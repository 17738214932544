define('settings',['app'], function (app) {
	'use strict';

	const ROLE_ADMIN = 'ROLE_ADMIN';
	const ROLE_ECONNECT = 'ROLE_ECONNECT';
	const ROLE_SUPERUSER = 'ROLE_SUPERUSER';

	return app
		.constant('BASE_DIRECTIVES_PATH', '/app/directives')
		.constant('BASE_MODULES_PATH', '/app/modules')
		.constant('BASE_TEMPLATES_PATH', '/template')
		.constant('EU_COUNTRIES', [
			'AUT',
			'BEL',
			'BGR',
			'HRV',
			'CYP',
			'CZE',
			'DNK',
			'EST',
			'FIN',
			'FRA',
			'DEU',
			'GRC',
			'HUN',
			'IRL',
			'ITA',
			'LVA',
			'LTU',
			'LUX',
			'MLT',
			'NLD',
			'POL',
			'PRT',
			'ROU',
			'SVK',
			'SVN',
			'ESP',
			'SWE',
			'GBR',
		])
		.constant('MENU_LEFT', [
			{
				identifier: 'DASHBOARD',
				label: 'NAVIGATION.DASHBOARD',
				icon: 'fa-bar-chart-o',
				requiredRole: ROLE_ADMIN,
				subMenu: [
					{
						label: 'NAVIGATION.DASHBOARD',
						icon: 'fa-tachometer',
						states: ['root.home'],
						single: 'SINGLE.DASHBOARD',
					},
					{
						label: 'NAVIGATION.REPORTS',
						icon: 'fa-table',
						states: ['root.reports', 'root.seminarDetailReport'],
						single: 'SINGLE.REPORT',
					},
				],
			},
			{
				identifier: 'WAWI',
				label: 'NAVIGATION.WAWI',
				icon: 'fa-shopping-cart',
				requiredRole: ROLE_ADMIN,
				subMenu: [
					{
						label: 'NAVIGATION.ORDERS',
						icon: 'fa-file-text',
						states: ['root.ordermanagements', 'root.ordermanagement', 'root.ordermanagementsetting'],
						single: 'SINGLE.ORDER',
					},
					{
						label: 'NAVIGATION.PRODUCTS',
						icon: 'fa-cubes',
						states: [
							'root.products',
							'root.productSimple',
							'root.productBundle',
							'root.productConfig',
							'root.productWizard',
						],
						single: 'SINGLE.PRODUCT',
					},
					{
						label: 'NAVIGATION.FINISHING_PRODUCTS',
						icon: 'fa-sticky-note',
						states: ['root.refinementProducts', 'root.refinementProduct'],
						single: 'SINGLE.FINNISHING_PRODUCT',
					},
					{
						label: 'NAVIGATION.SEMINARS',
						icon: 'fa-users',
						states: ['root.seminars', 'root.seminar'],
						single: 'SINGLE.SEMINAR',
					},
					{
						label: 'NAVIGATION.CATEGORIES',
						icon: 'fa-list',
						states: ['root.categories', 'root.category'],
						single: 'SINGLE.CATEGORY',
					},
					{
						label: 'NAVIGATION.TAGS',
						icon: 'fa-tag',
						states: ['root.tags', 'root.tag'],
						single: 'SINGLE.TAG',
					},
					{
						label: 'NAVIGATION.ATTRIBUTES',
						icon: 'fa-sitemap fa-rotate-90',
						states: ['root.attributes', 'root.attribute'],
						single: 'SINGLE.ATTRIBUTE',
					},
				],
			},
			{
				identifier: 'CRM',
				label: 'NAVIGATION.CRM',
				icon: 'fa-user',
				requiredRole: ROLE_ADMIN,
				subMenu: [
					{
						label: 'NAVIGATION.PERSONS',
						icon: 'fa-users',
						states: ['root.customers', 'root.customer'],
						single: 'SINGLE.PERSON',
					},
					{
						label: 'NAVIGATION.ORGANIZATIONS',
						icon: 'fa-industry',
						states: ['root.organisations', 'root.organisation'],
						single: 'SINGLE.ORGANIZATION',
					},
					{
						label: 'NAVIGATION.CUSTOMER_GROUPS',
						icon: 'fa-users',
						states: ['root.customergroups', 'root.customergroup'],
						single: 'SINGLE.CUSTOMER_GROUP',
					},
					{
						label: 'NAVIGATION.TICKETS',
						icon: 'fa-ticket',
						states: ['root.tickets', 'root.ticket'],
						single: 'SINGLE.TICKET',
					},
				],
			},
			{
				identifier: 'CMS',
				label: 'NAVIGATION.CMS',
				icon: 'fa-pencil',
				requiredRole: ROLE_ADMIN,
				subMenu: [
					{
						label: 'NAVIGATION.TEMPLATES',
						icon: 'fa-window-maximize',
						states: ['root.templatePages', 'root.templatePage'],
						single: 'SINGLE.TEMPLATE',
					},
					{
						label: 'NAVIGATION.CONTENT_PAGE',
						icon: 'fa-address-card-o',
						states: ['root.contentPages', 'root.contentPage'],
						single: 'SINGLE.CONTENT_PAGE',
					},
					{
						label: 'NAVIGATION.FILTER_PAGES',
						icon: 'fa-filter',
						states: ['root.filterPages', 'root.filterPage'],
						single: 'SINGLE.FILTER_PAGE',
					},
					{
						label: 'NAVIGATION.GLOSSARY_PAGE',
						icon: 'fa-address-card-o',
						states: ['root.glossaryPages', 'root.glossaryPage'],
						single: 'SINGLE.GLOSSARY_PAGE',
					},
					{
						label: 'NAVIGATION.LANDING_PAGES',
						icon: 'fa-address-card-o',
						states: ['root.landingPages', 'root.landingPage'],
						single: 'SINGLE.LANDING_PAGE',
					},
					{
						label: 'NAVIGATION.TAGS',
						icon: 'fa-tag',
						states: ['root.cmstags', 'root.cmstag'],
						single: 'SINGLE.TAG',
					},
					{
						label: 'NAVIGATION.REVIEWS',
						icon: 'fa-star',
						states: ['root.productRatings', 'root.productRating'],
						single: 'SINGLE.RATING',
					},
					{
						label: 'NAVIGATION.FAQ',
						icon: 'fa-star',
						states: ['root.productFaqs', 'root.productFaq'],
						single: 'SINGLE.FAQ',
					},
				],
			},
			{
				identifier: 'ECON',
				label: 'NAVIGATION.ECON', // exact value is used in LoginController
				icon: 'fa-envelope',
				requiredRole: ROLE_ECONNECT,
				subMenu: [
					{
						label: 'NAVIGATION.DASHBOARD',
						icon: 'fa-tachometer',
						states: ['root.econDashboard'],
						single: 'Dashboard',
					},
					{
						label: 'NAVIGATION.RECIPIENT',
						icon: 'fa-user',
						states: ['root.econRecipients', 'root.econRecipient'],
						single: 'Empfänger',
					},
					{
						label: 'NAVIGATION.RECIPIENT_LIST',
						icon: 'fa-users',
						states: ['root.econRecipientLists', 'root.econRecipientList'],
						single: 'Empfängerliste',
					},
					{
						label: 'NAVIGATION.TEMPLATES',
						icon: 'fa-file-code-o',
						states: ['root.econMailtemplates', 'root.econMailtemplate'],
						single: 'Newslettervorlage',
					},
					{
						label: 'NAVIGATION.NEWSLETTER',
						icon: 'fa-file-text',
						states: ['root.econNewsletters', 'root.econNewsletter'],
						single: 'Newsletter',
					},
					{
						label: 'NAVIGATION.AUTOMATION',
						icon: 'fa-magic',
						states: ['root.econAutomations', 'root.econAutomation'],
						single: 'Automatisierung',
					},
					{
						label: 'NAVIGATION.CAMPAIGNS',
						icon: 'fa-caret-square-o-right',
						states: ['root.econCampaigns', 'root.econCampaign'],
						single: 'Kampagne',
					},
				],
			},
		])
		.constant('MENU_RIGHT', [
			{
				identifier: 'SHOP_SELECTOR',
				label: 'Shopauswahl',
				icon: 'fa-home',
				requiredRole: ROLE_ADMIN,
			},
			{
				identifier: 'SETTINGS',
				label: 'NAVIGATION.SETTINGS',
				icon: 'fa-gears',
				subMenu: [
					{
						label: 'NAVIGATION.SHOPS',
						icon: 'fa-shopping-cart',
						requiredRole: ROLE_ADMIN,
						states: ['root.shops', 'root.shop'],
						single: 'SINGLE.SHOP',
					},
					{
						label: 'NAVIGATION.USER',
						icon: 'fa-user',
						requiredRole: ROLE_ADMIN,
						states: ['root.users', 'root.user'],
						single: 'SINGLE.USER',
					},
					{
						label: 'NAVIGATION.TAX_RATES',
						icon: 'fa-university',
						requiredRole: ROLE_ADMIN,
						states: ['root.taxes', 'root.tax'],
						single: 'SINGLE.TAX_RATE',
					},
					{
						label: 'NAVIGATION.IMPORT_EXPORT',
						icon: 'fa-arrow-circle-up',
						requiredRole: ROLE_ADMIN,
						states: ['root.importExportCSV'],
						single: 'SINGLE.IMPORT_EXPORT',
					},
					{
						label: 'NAVIGATION.DATAFEEDS',
						icon: 'fa-database',
						requiredRole: ROLE_ADMIN,
						states: ['root.dataFeedInputs'],
						single: 'SINGLE.DATAFEED',
					},
					{
						label: 'NAVIGATION.UNITS',
						icon: 'fa-arrows-h',
						requiredRole: ROLE_ADMIN,
						states: ['root.units', 'root.unit'],
						single: 'SINGLE.UNIT',
					},
					{
						label: 'NAVIGATION.CURRENCIES',
						icon: 'fa-money',
						requiredRole: ROLE_ADMIN,
						states: ['root.currencies', 'root.currency'],
						single: 'FIELDS.CURRENCY',
					},
					{
						label: 'NAVIGATION.BANK_ACCOUNTS',
						icon: 'fa-usd',
						requiredRole: ROLE_ADMIN,
						states: ['root.bankAccounts', 'root.bankAccount'],
						single: 'SINGLE.BANK_ACCOUNT',
					},
					{
						label: 'NAVIGATION.BRANCHES',
						icon: 'fa-industry',
						requiredRole: ROLE_ADMIN,
						states: ['root.branches', 'root.branch'],
						single: 'SINGLE.BRANCH',
					},
					{
						label: 'NAVIGATION.SYSTEM_MESSAGES',
						icon: 'fa-envelope-o',
						requiredRole: ROLE_ADMIN,
						states: ['root.systemMessages', 'root.systemMessage'],
						single: 'SINGLE.SYSTEM_MESSAGE',
					},
					{
						label: 'NAVIGATION.CLASSIFICATIONS',
						icon: 'fa-thumb-tack',
						requiredRole: ROLE_ADMIN,
						states: ['root.classifications', 'root.classification'],
						single: 'SINGLE.CLASSIFICATION',
					},
					{
						label: 'NAVIGATION.ECON',
						icon: 'fa-envelope',
						requiredRole: ROLE_ECONNECT,
						states: ['root.econSettings', 'root.econSettings'],
						single: 'SINGLE.ECON',
					},
					{
						label: 'NAVIGATION.PRODUCT_FEATURE_SETS',
						icon: 'fa-list',
						requiredRole: ROLE_ADMIN,
						states: ['root.metadatasets', 'root.metadataset'],
						single: 'SINGLE.PRODUCT_FEATURE_SET',
					},
					{
						label: 'NAVIGATION.PRODUCT_FEATURES',
						icon: 'fa-info',
						requiredRole: ROLE_ADMIN,
						states: ['root.metadatas', 'root.metadata'],
						single: 'SINGLE.PRODUCT_FEATURE',
					},
					{
						label: 'NAVIGATION.PRICE_RULES',
						icon: 'fa-cart-arrow-down',
						requiredRole: ROLE_ADMIN,
						states: ['root.pricerules', 'root.pricerule'],
						single: 'SINGLE.PRICE_RULE',
					},
					{
						label: 'NAVIGATION.COUPON_CODES',
						icon: 'fa-money',
						requiredRole: ROLE_ADMIN,
						states: ['root.couponcodes', 'root.couponcode'],
						single: 'SINGLE.DISCOUNT_CODE',
					},
					{
						label: 'NAVIGATION.LOGS',
						icon: 'fa-info',
						requiredRole: ROLE_ADMIN,
						states: ['root.logs'],
						single: 'SINGLE.LOG',
					},
					{
						label: 'NAVIGATION.SYSTEM_INFORMATION',
						icon: 'fa-laptop',
						requiredRole: ROLE_SUPERUSER,
						states: ['root.systemInformation'],
						single: 'SINGLE.SYSTEM_INFO',
					},
					{
						label: 'NAVIGATION.CACHE',
						icon: 'fa-eraser',
						requiredRole: ROLE_ADMIN,
						states: ['root.cache'],
						single: 'SINGLE.CACHE',
					},
					{
						label: 'NAVIGATION.BACKUP',
						icon: 'fas fa-copy',
						requiredRole: ROLE_SUPERUSER,
						states: ['root.backup'],
						single: 'SINGLE.BACKUP',
					},
				],
			},
			{
				identifier: 'LOGOUT',
				label: 'NAVIGATION.LOGOUT',
				icon: 'fa-sign-out',
				states: ['root.login'],
			},
		])
		.constant('PRODUCT_FILTER_OPTIONS', [
			{
				id: 'productNumber',
				name: 'Artikelnummer',
				types: [
					{
						id: 'equals',
						type: 'ist gleich',
					},
					{
						id: 'contains',
						type: 'enthält',
					},
				],
			},
			{
				id: 'productId',
				name: 'Produkt Id',
				types: [
					{
						id: '<=',
						type: '<=',
					},
					{
						id: '>=',
						type: '>=',
					},
					{
						id: '=',
						type: '=',
					},
				],
			},
			{
				id: 'productTitle',
				name: 'Artikelbezeichnung',
				types: [
					{
						id: 'contains',
						type: 'enthält',
					},
					{
						id: 'equals',
						type: 'ist gleich',
					},
					{
						id: 'startsWith',
						type: 'beginnt mit',
					},
					{
						id: 'endsWith',
						type: 'endet mit',
					},
				],
			},
			{
				id: 'productPrice',
				name: 'Artikelpreis',
				types: [
					{
						id: '<=',
						type: '<=',
					},
					{
						id: '>=',
						type: '>=',
					},
					{
						id: '=',
						type: '=',
					},
				],
			},
			{
				id: 'productMetaTag',
				name: 'Artikelmerkmal',
				types: [
					{
						id: 'contains',
						type: 'enthält',
					},
					{
						id: 'notContains',
						type: 'enthält nicht',
					},
				],
			},
			{
				id: 'productAttribute',
				name: 'Artikelattribut',
				types: [
					{
						id: 'contains',
						type: 'enthält',
					},
					{
						id: 'notContains',
						type: 'enthält nicht',
					},
				],
			},
			{
				id: 'productCategory',
				name: 'Kategorie',
				types: [
					{
						id: 'equals',
						type: 'ist gleich',
					},
				],
			},
			{
				id: 'productFlag',
				name: 'Markierung',
				types: [
					{
						id: 'equals',
						type: 'ist gleich',
					},
					{
						id: 'notEquals',
						type: 'ist nicht gleich',
					},
					{
						id: 'notNull',
						type: 'ist markiert',
					},
					{
						id: 'null',
						type: 'ist nicht markiert',
					},
				],
			},
		])
		.constant('PRIMARKETS_EVENTS', [
			{
				type: 'SYSTEM_EVENT',
				title: 'User Password Reset Before',
				key: 'USER_PASSWORD_RESET_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Password Reset Before',
				key: 'PASSWORD_RESET_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Password Reset After',
				key: 'PASSWORD_RESET_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Reset Email Before',
				key: 'EMAIL_RESET_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Reset Email After',
				key: 'EMAIL_RESET_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Email Activation Before',
				key: 'EMAIL_ACTIVATION_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Email Activation After',
				key: 'EMAIL_ACTIVATION_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Send Ticket to Customer Before',
				key: 'TICKET_TO_CUSTOMER_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Send Ticket to Customer After',
				key: 'TICKET_TO_CUSTOMER_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Send Ticket to Admin Before',
				key: 'TICKET_TO_ADMIN_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Send Ticket to Admin After',
				key: 'TICKET_TO_ADMIN_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Organisation Invitation Before',
				key: 'ORGANISATION_INVITATION_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Organisation Invitation After',
				key: 'ORGANISATION_INVITATION_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Account Activation Before',
				key: 'ACCOUNT_ACTIVATION_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Account Activation After',
				key: 'ACCOUNT_ACTIVATION_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Pending Account Activation Before',
				key: 'PENDING_ACCOUNT_ACTIVATION_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Pending Account Activation After',
				key: 'PENDING_ACCOUNT_ACTIVATION_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Drop Shipping Organisation Before',
				key: 'DROP_SHIPPING_ORGANISATION_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Drop Shipping Organisation AFTER',
				key: 'DROP_SHIPPING_ORGANISATION_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Document Upload Notification to User Before',
				key: 'DOCUMENT_NOTIFICATION_USER_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Document Upload Notification to User After',
				key: 'DOCUMENT_NOTIFICATION_USER_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Document Upload Notification to Admin Before',
				key: 'DOCUMENT_NOTIFICATION_ADMIN_BEFORE',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Document Upload Notification to Admin After',
				key: 'DOCUMENT_NOTIFICATION_ADMIN_AFTER',
			},
			{
				type: 'SYSTEM_EVENT',
				title: 'Shop order cancelled after',
				key: 'SHOP_ORDER_CANCELED_AFTER',
			},
		])
		.constant('LOCALES', [
			{ id: 1, isDefault: true, language: 'LOCALES.DE', locale: 'de' },
			{ id: 2, isDefault: false, language: 'LOCALES.EN', locale: 'en' },
			{ id: 3, isDefault: false, language: 'LOCALES.FR', locale: 'fr' },
			{ id: 4, isDefault: false, language: 'LOCALES.CZ', locale: 'cz' },
		]);
});

