define('directives/listSelectAmount/ListSelectAmount',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngListSelectAmount', ['BASE_DIRECTIVES_PATH', ngListSelectAmount]);
});
function ngListSelectAmount(BASE_DIRECTIVES_PATH) {
	return {
		scope: {
			entityService: '=',
			selectedItems: '=',
			fields: '=',
			name: '=',
			isAmountRequired: '=?', // true by default
		},
		templateUrl: BASE_DIRECTIVES_PATH + '/listSelectAmount/views/view.html',
		controller: ['$scope', NgListSelectAmountController],
	};
}
function NgListSelectAmountController($scope) {
	if ($scope.isAmountRequired === undefined) {
		$scope.isAmountRequired = true;
	}

	$scope.query = {};
	$scope.query.filters = {};
	$scope.firstPageLoaded = false;

	$scope.$watch('items', function (newValue, oldValue) {
		if ($scope.firstPageLoaded || !$scope.entityService) {
			return;
		}
		$scope.entityService.setCurrentPage(0).then(function () {
			$scope.items = $scope.entityService.getAll();
			$scope.totalItems = $scope.entityService.getTotalItems();
		});
		$scope.firstPageLoaded = true;
	});

	$scope.searchInputChanged = function () {
		if ($scope.filterTimer) {
			clearTimeout($scope.filterTimer);
		}

		var textfield = this;
		$scope.filterTimer = setTimeout(function () {
			$scope.query.filters[textfield.field.attribute] = textfield.fieldModel;
			$scope.entityService.setQuery($scope.query).then(function () {
				$scope.items = $scope.entityService.getAll();
				$scope.totalItems = $scope.entityService.getTotalItems();
			});
		}, 500);
	};

	$scope.listSelectPageChanged = function () {
		$scope.entityService.setCurrentPage($scope.selectedPage - 1).then(function () {
			$scope.items = $scope.entityService.getAll();
		});
	};

	$scope.treeOptions = {
		accept: function (sourceNodeScope, destNodesScope, destIndex) {
			return true;
		},
		dropped: function () {
			for (let i in $scope.selectedItems) {
				$scope.selectedItems[i].sortOrder = +i + 1;
			}
		},
	};

	$scope.$watch('items', function (newValue, oldValue) {
		if (newValue === undefined || newValue == null) {
			return;
		}
		$scope.selectItems();
	});

	$scope.selectItems = function () {
		if (!$scope.selectedItems) {
			console.error("The attribute selected-items='X' is not set. Please set it to use the list control.");
			return;
		}

		for (var i = 0; i < $scope.items.length; i++) {
			var item = $scope.items[i];

			for (var j = 0; j < $scope.selectedItems.length; j++) {
				var selectedItem = $scope.selectedItems[j];
				if (item.id == selectedItem.productId) {
					if (!item[$scope.name]) {
						item[$scope.name] = {};
					}
					item[$scope.name].selected = false;
					item[$scope.name].added = true;
					item.amount = selectedItem.amount;
				}
			}
		}
	};

	$scope.addItems = function () {
		for (var i = 0; i < $scope.items.length; i++) {
			if (canItemBeAdded($scope.items[i])) {
				//TODO NUR kleines OBJECT
				var tmp = {};
				tmp.amount = $scope.items[i].amount;
				tmp.basePrice = $scope.items[i].basePrice;
				tmp.sku = $scope.items[i].sku;
				tmp.name = $scope.items[i].name;
				tmp.productId = $scope.items[i].id;
				tmp[$scope.name] = {};

				$scope.selectedItems.push(tmp);

				$scope.items[i][$scope.name].added = true;
				$scope.items[i][$scope.name].selected = false;
			}
		}
	};

	$scope.removeItems = function () {
		for (var i = $scope.selectedItems.length - 1; i >= 0; i--) {
			if ($scope.selectedItems[i][$scope.name] && $scope.selectedItems[i][$scope.name].selected) {
				for (var j = 0; j < $scope.items.length; j++) {
					if ($scope.items[j].id == $scope.selectedItems[i].productId) {
						$scope.items[j][$scope.name].added = false;
						$scope.items[j][$scope.name].selected = false;
					}
				}

				$scope.selectedItems.splice(i, 1);
			}
		}
	};

	$scope.itemsLeftSelected = function () {
		if ($scope.items != undefined) {
			for (var i = 0; i < $scope.items.length; i++) {
				if (canItemBeAdded($scope.items[i])) {
					return true;
				}
			}
			return false;
		}
		return false;
	};

	$scope.itemsRightSelected = function () {
		for (var i = 0; i < $scope.selectedItems.length; i++) {
			if ($scope.selectedItems[i][$scope.name] && $scope.selectedItems[i][$scope.name].selected) {
				return true;
			}
		}
		return false;
	};

	const canItemBeAdded = (item) => {
		if (!item) {
			return false;
		}

		return (
			item[$scope.name] &&
			item[$scope.name].selected &&
			!item[$scope.name].added &&
			(!$scope.isAmountRequired || item.amount > 0)
		);
	};
}
;
