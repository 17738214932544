define('modules/cms/controllers/ProductRatingController',['modules/module', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('ProductRatingController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		ProductRatingController,
	]);
});

function ProductRatingController($scope, $controller, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'PRODUCT_SKU',
			heading: 'FIELDS.SKU',
			attribute: 'productSku',
			searchDisabled: true,
			orderDisabled: true,
		},
		{
			id: 'CUSTOMER_FULL_NAME',
			heading: 'FIELDS.CUSTOMER',
			attribute: 'customerFullName',
			searchDisabled: true,
			orderDisabled: true,
		},
		{
			id: 'RATING',
			heading: 'FIELDS.RATING',
			attribute: 'rating',
		},
		{
			id: 'COMMENT',
			heading: 'FIELDS.COMMENT',
			template: BASE_TEMPLATES_PATH + '/cms/productRating/comment-col.html',
			attribute: 'comment',
		},
		{
			id: 'STATUS',
			heading: 'FIELDS.STATUS',
			attribute: 'publishState',
		},
		{
			id: 'DATE',
			heading: 'FIELDS.DATE',
			template: BASE_TEMPLATES_PATH + '/cms/productRating/rating-date-col.html',
			attribute: 'createdAt',
			searchDisabled: true,
		},
	];

	$scope.formState = 'root.productRating';

	$scope.init('ProductRating');
}
;
