/**
 * Created by mrafiq on 03.07.18.
 */
define('modules/cms/controllers/ProductRatingFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'directives/languageForm/languageForm',
	'services/CustomerService',
	'services/ProductService',
	'modules/cms/controllers/RatingSelectProductController',
	'modules/cms/controllers/RatingSelectCustomerController',

	'settings',
], function (module) {
	'use strict';
	module.controller('ProductRatingFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'CustomerService',
		'ProductService',
		'PublishStateService',
		'ValidationService',
		ProductRatingFormController,
	]);
});

function ProductRatingFormController(
	$scope,
	$controller,
	BASE_TEMPLATES_PATH,
	CustomerService,
	ProductService,
	PublishStateService,
	ValidationService
) {
	$scope.formTabs = [
		{
			label: 'FIELDS.GENERAL',
			template: BASE_TEMPLATES_PATH + '/cms/productRating/form-main.html',
			active: true,
		},
	];

	$scope.listState = 'root.productRatings';
	$scope.statusSwitch;
	$scope.hoveredStar;

	angular.extend(
		this,
		$controller('EntityFormController', {
			$scope: $scope,
		})
	);

	PublishStateService.findAll().then(function () {
		$scope.publishStates = PublishStateService.getAll(true);
	});

	$scope.init('ProductRating').then(function () {
		if ($scope.newEntity) {
			$scope.entity.publishState = 'ACTIVE';
		}

		if ($scope.entity.publishState == 'ACTIVE') $scope.statusSwitch = true;

		if ($scope.entity.publishState == 'MAINTENANCE') $scope.statusSwitch = false;

		if ($scope.entity.customerId != undefined) {
			CustomerService.findOne($scope.entity.customerId).then(function () {
				var customer = CustomerService.getOne();
				if (customer.error) {
					$scope.customer = 'Gastkauf';
				} else {
					$scope.customer = customer.firstName + ' ' + customer.lastName;
					$scope.entity.customerId = customer.id;
				}
			});

			ProductService.findByDefaultLocale($scope.entity.productId).then(function (response) {
				$scope.product = response;
			});
		}
		if ($scope.entity.rating) {
			$scope.setRating($scope.entity.rating);
			$scope.setHoveredStar($scope.entity.rating);
		} else {
			$scope.setRating(0);
			$scope.setHoveredStar(0);
		}

		if ($scope.entity.firstName !== '' || $scope.entity.lastName !== '') {
			$scope.selectCustomer = false;
		} else {
			$scope.selectCustomer = true;
		}
	});

	$scope.switchCustomerSelect = () => {
		$scope.selectCustomer = !$scope.selectCustomer;
		// $scope.entity.firstname = '';
		// $scope.entity.lastname = '';
		// $scope.customer = '';
		// $scope.entity.customerId = undefined;
	};

	$scope.onStatusChange = function (cbState) {
		if (cbState) $scope.entity.publishState = 'ACTIVE';
		if (!cbState) $scope.entity.publishState = 'MAINTENANCE';
	};

	$scope.onCancel = function () {
		$scope.entityService.setType('ProductRating');
	};

	$scope.setHoveredStar = (star) => {
		$scope.hoveredStar = star;
	};

	$scope.setRating = (rating) => {
		$scope.entity.rating = rating;
	};

	$scope.dialogCustomerSelectController = [
		'$scope',
		'dataToPass',
		'$mdDialog',
		function ($dialogCustomerScope, dataToPass, $mdDialog) {
			angular.extend(
				this,
				$controller('RatingSelectCustomerController', {
					$scope: $dialogCustomerScope,
				})
			);

			$dialogCustomerScope.setCustomer = function (customer) {
				$scope.customer = customer.firstName + ' ' + customer.lastName;
				$scope.entity.customerId = customer.id;
				$scope.entityService.setType('ProductRating');
				$mdDialog.hide();
			};

			$dialogCustomerScope.cancel = function () {
				$mdDialog.cancel();
			};

			$dialogCustomerScope.addSelectedCustomerAndBack = function (inv, ship) {
				if ($dialogCustomerScope.selected.items.length == 1) {
					$dialogCustomerScope.addSelectedCustomer();
					$mdDialog.hide($dialogCustomerScope.customer);
				}
			};
		},
	];

	$scope.dialogProductSelectController = [
		'$scope',
		'dataToPass',
		'$mdDialog',
		function ($dialogProductScope, dataToPass, $mdDialog) {
			angular.extend(
				this,
				$controller('RatingSelectProductController', {
					$scope: $dialogProductScope,
				})
			);

			$dialogProductScope.cancel = function () {
				$mdDialog.cancel();
			};

			$dialogProductScope.addSelectedProductAndBack = function () {
				if ($dialogProductScope.selected.items.length == 0) alert('Please select a product');
				if ($dialogProductScope.selected.items.length > 1) alert('Please select only one product');
				if ($dialogProductScope.selected.items.length == 1) {
					$dialogProductScope.addSelectedProduct();
					$mdDialog.hide();
				}
			};

			$dialogProductScope.addSelectedProduct = function () {
				$scope.product = $dialogProductScope.selected.items[0];
				$scope.entity.productId = $scope.product.id;
				$scope.entityService.setType('ProductRating');
			};
		},
	];
}
;
