define('modules/wawi/product/controllers/tabs/PriceTabController',[], function () {
	'use strict';

	return ($parentScope, $scope, { ShopService, SessionValuesService, BASE_TEMPLATES_PATH }) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.PRICE',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-prices.html',
		});

		$scope.initProductPricesArray = (permited, sortProductPrices) => {
			if (!$parentScope.entity || !permited) {
				return;
			}

			if (!$parentScope.entity.prices || !$parentScope.entity.prices.length) {
				//TODO: hard coded group id. Where can I get the group id. Is the feature groups still existent?
				$parentScope.entity.prices = [{ fromAmount: 1, toAmount: -1, customerGroup: { id: 1 } }];
			} else {
				if (sortProductPrices) {
					$parentScope.entity.prices.sort(function (a, b) {
						return a.fromAmount - b.fromAmount;
					});
				}

				for (let price of $parentScope.entity.prices) {
					price.fromAmount = price.fromAmount ? price.fromAmount.toString() : '';
					price.toAmount = price.toAmount ? price.toAmount.toString() : '';
				}
			}
		};

		$scope.initPrices = function () {
			var shopId = SessionValuesService.getShop();

			var set = new Set();
			if ($parentScope.entity.prices == undefined) {
				$parentScope.entity.prices = [];
			}
			/*for(var i=0; i < $parentScope.entity.prices.length; i++) {
				set.add($parentScope.entity.prices[i].customerGroup.id);
			}
			var list = Array.from(set);
			$scope.customerGroups = [];
			for(var j=0; j < list.length; j++) {
				for(var i=0; i < $parentScope.entity.prices.length; i++) {
					if(list[j] === $parentScope.entity.prices[i].customerGroup.id){
						$scope.customerGroups.push($parentScope.entity.prices[i].customerGroup);
						break;
					}
				}
			}
			*/
			ShopService.findOne(shopId).then(function () {
				var shop = ShopService.getOne();
				$parentScope.availableData.customerGroupId = shop.defaultCustomerGroupId;
				/* if(!set.has($scope.customerGroupId)) {
					$scope.customerGroupId = list[0];
				}*/
			});

			$scope.initProductPricesArray();
		};

		$scope.removePricesWithNoSellingPrice = () => {
			if ($parentScope.entity.prices) {
				$parentScope.entity.prices = $parentScope.entity.prices.filter((productPrice) => {
					return productPrice.sellingPrice !== undefined && productPrice.sellingPrice !== null;
				});
			}
		};

		$parentScope.$on('afterInit', function () {
			if ($parentScope.entity.prices == undefined || $parentScope.entity.prices.length == 0) {
				//TODO: hard coded group id. Where can I get the group id. Is the feature groups still existent?
				$parentScope.entity.prices = [{ fromAmount: 1, toAmount: -1, customerGroup: { id: 1 } }];
			} else {
				for (let index in $parentScope.entity.prices) {
					$parentScope.entity.prices.sort(function (a, b) {
						return a.fromAmount - b.fromAmount;
					});
					$parentScope.entity.prices[index].fromAmount = $parentScope.entity.prices[
						index
					].fromAmount.toString();
					$parentScope.entity.prices[index].toAmount = $parentScope.entity.prices[index].toAmount.toString();
				}
			}
		});

		$scope.showAddButton = function () {
			if (
				$parentScope.entity &&
				$parentScope.entity.prices.filter(function (value) {
					if (value.customerGroup.id === $parentScope.availableData.customerGroupId) {
						return true;
					} else {
						return false;
					}
				}).length === 0
			) {
				return true;
			}
			return false;
		};

		$scope.addPrice = function (price) {
			if (price === undefined) {
				$parentScope.entity.prices.push({
					fromAmount: '1',
					toAmount: '-1',
					sellingPrice: 0,
					customerGroup: {
						id: $parentScope.availableData.customerGroupId,
					},
				});
				return;
			}
			if (parseInt(price.toAmount) >= parseInt(price.fromAmount)) {
				var fromAmountNew = (parseInt(price.toAmount) + 1).toString();
				$parentScope.entity.prices.push({
					fromAmount: fromAmountNew,
					toAmount: '-1',
					sellingPrice: price.sellingPrice,
					pseudoPrice: price.pseudoPrice,
					customerGroup: {
						id: $parentScope.availableData.customerGroupId,
					},
				});
				jQuery('.last-item input').css('border-color', '');
			} else {
				jQuery('.last-item input').css('border-color', 'red');
			}
			$scope.checkRange();
		};

		$scope.removePrice = function (price) {
			const priceIndex = $parentScope.entity.prices.indexOf(price);
			if (priceIndex === -1) {
				// Should not happen
				return;
			} else {
				$parentScope.entity.prices.splice(priceIndex, 1);
			}

			const customerGroupPrices = $parentScope.entity.prices.filter((productPrice) => {
				return productPrice.customerGroup.id === price.customerGroup.id;
			});

			const customerGroupLastPrice = customerGroupPrices[customerGroupPrices.length - 1];

			if (customerGroupLastPrice !== undefined) {
				customerGroupLastPrice.toAmount = -1;
			}
		};

		$scope.checkRange = function () {
			console.log('checkRange');

			/*for(var i=0; i<$parentScope.entity.prices.length; i++)
			{
				if(i > 0) {
					if(parseInt($parentScope.entity.prices[i].fromAmount) < parseInt($parentScope.entity.prices[i-1].fromAmount)) {
						NotificationService.notifyGeneralError({
							statusText: 'Fehler in der Preisstaffel'
						});
					}
				}

			   /* if(i == $parentScope.entity.prices.length-1){
					if($parentScope.entity.prices[i].fromAmount > $parentScope.entity.prices[i].toAmount && $parentScope.entity.prices[i].toAmount != -1){

						NotificationService.notifyGeneralError({
							statusText: 'Fehler in der Preisstaffel1'
						});
						return false;
					}
				} else {
					/* same range check to greater than from check
					if($parentScope.entity.prices[i].fromAmount > $parentScope.entity.prices[i].toAmount){
						NotificationService.notifyGeneralError({
							statusText: 'Fehler in der Preisstaffel2'
						});
						return false;
					}
				}
				if(i>=1){
					/* previous fromPrice check with current toPrice
					if($parentScope.entity.prices[i].fromAmount <= $parentScope.entity.prices[i-1].toAmount){
						NotificationService.notifyGeneralError({
							statusText: 'Fehler in der Preisstaffel3'
						});
						return false;
					}
				}

			}*/

			return true;
		};

		/**
		 * Is used in ProctBundleFormController too
		 */
		$scope.isAtLeastOneSellingPricePresent = (product) => {
			const productPrice = product.prices.find(
				(productPrice) => productPrice.sellingPrice !== null && productPrice.sellingPrice !== undefined
			);

			return productPrice !== undefined;
		};

		$scope.setPriceDefaultValues = () => {
			$parentScope.entity.showPriceSearch = true;
			$parentScope.entity.showPriceCatalog = true;
			$parentScope.entity.showPriceDetail = true;
		};
	};
});

