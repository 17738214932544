define('routes',[
	'modules/navigation/controllers/NavigationController',
	'modules/navigation/controllers/FooterController',
	'modules/wawi/tag/controllers/TagListController',
	'modules/wawi/tag/controllers/TagFormController',
	'modules/econ/controllers/EconCampaignController',
	'modules/econ/controllers/EconCampaignFormController',
	'modules/econ/controllers/EconRecipientController',
	'modules/econ/controllers/EconRecipientFormController',
	'modules/econ/controllers/EconMailtemplateController',
	'modules/econ/controllers/EconMailtemplateFormController',
	'modules/econ/controllers/EconNewsletterController',
	'modules/econ/controllers/EconNewsletterFormController',
	'modules/econ/controllers/EconRecipientListController',
	'modules/econ/controllers/EconRecipientListFormController',
	'modules/econ/controllers/EconAutomationController',
	'modules/econ/controllers/EconAutomationFormController',
	'modules/content/controllers/ContentController',
	'modules/content/controllers/ContentFormController',
	'modules/wawi/product/controllers/ProductController',
	'modules/wawi/product/controllers/ProductFormController',
	'modules/wawi/product/controllers/ProductConfigFormController',
	'modules/wawi/product/controllers/ProductWizardController',
	'modules/wawi/product/controllers/ProductBundleFormController',
	'modules/crm/customer/controllers/CustomerController',
	'modules/crm/customer/controllers/CustomerFormController',
	'modules/crm/organisation/controllers/OrganisationListController',
	'modules/crm/organisation/controllers/OrganisationFormController',
	'modules/crm/ticket/controllers/TicketListController',
	'modules/crm/ticket/controllers/TicketFormController',
	'modules/shippingGroup/controllers/ShippingGroupController',
	'modules/shippingGroup/controllers/ShippingGroupFormController',
	'modules/shippingRules/controllers/ShippingRuleController',
	'modules/shippingRules/controllers/ShippingRuleFormController',
	'modules/shippingGlobal/controllers/ShippingGlobalController',
	'modules/shippingGlobal/controllers/ShippingGlobalFormController',
	'modules/wawi/category/controllers/CategoryController',
	'modules/wawi/category/controllers/CategoryFormController',
	'modules/orderManagement/controllers/OrderManagementController',
	'modules/orderManagement/controllers/OrderManagementFormController',
	'modules/wawi/attribute/controllers/AttributeController',
	'modules/wawi/attribute/controllers/AttributeFormController',
	'modules/econ/controllers/EconDashboardController',
	'modules/imEx/controllers/ImExProfilesController',
	'modules/imEx/controllers/ImExProfilesFormController',
	'modules/imEx/controllers/ServerProfilesController',
	'modules/imEx/controllers/ServerProfilesFormController',
	'modules/settings/cache/CacheController',
	'modules/settings/country/CountryController',
	'modules/settings/country/CountryFormController',
	'modules/settings/shop/ShopController',
	'modules/settings/shop/ShopFormController',
	'modules/settings/tax/TaxController',
	'modules/settings/tax/TaxFormController',
	'modules/settings/dataFeedInput/DataFeedInputController',
	'modules/settings/dataFeedInput/DataFeedInputFormController',
	'modules/settings/user/UserController',
	'modules/settings/user/UserFormController',
	'modules/settings/log/LogController',
	'modules/settings/currency/CurrencyController',
	'modules/settings/currency/CurrencyFormController',
	'modules/settings/bankAccount/BankAccountController',
	'modules/settings/bankAccount/BankAccountFormController',
	'modules/crm/customerGroup/CustomerGroupController',
	'modules/crm/customerGroup/CustomerGroupFormController',
	'modules/settings/unit/UnitController',
	'modules/settings/unit/UnitFormController',
	'modules/settings/systemMessage/SystemMessageListController',
	'modules/settings/systemMessage/SystemMessageFormController',
	'modules/settings/systemInformation/SystemInformationController',
	'modules/settings/shop//tabs/SmtpTabController',
	'modules/settings/classification/ClassificationListController',
	'modules/settings/classification/ClassificationFormController',
	'modules/settings/branch/BranchListController',
	'modules/settings/branch/BranchFormController',
	'modules/settings/couponCode/CouponCodeFormController',
	'modules/settings/couponCode/CouponCodeListController',
	'modules/settings/backup/BackupController',
	'modules/settings/priceRule/PriceRuleListController',
	'modules/settings/priceRule/PriceRuleFormController',
	'modules/cms/nodes/NodeController',
	'modules/cms/nodes/NodeFormController',
	'modules/cms/controllers/FilterPageController',
	'modules/cms/controllers/FilterPageFormController',
	'modules/cms/controllers/TemplatePageController',
	'modules/cms/controllers/TemplatePageFormController',
	'modules/cms/controllers/ContentPageController',
	'modules/cms/controllers/ContentPageFormController',
	'modules/cms/controllers/GlossaryPageController',
	'modules/cms/controllers/GlossaryPageFormController',
	'modules/cms/controllers/LandingPageController',
	'modules/cms/controllers/LandingPageFormController',
	'modules/cms/controllers/CmsTagListController',
	'modules/cms/controllers/CmsTagFormController',
	'modules/cms/controllers/ProductRatingController',
	'modules/cms/controllers/ProductRatingFormController',
	'modules/cms/controllers/ProductFaqController',
	'modules/cms/controllers/ProductFaqFormController',
	'modules/wawi/seminar/SeminarListController',
	'modules/wawi/seminar/SeminarFormController',
	'modules/wawi/refinementProduct/RefinementProductListController',
	'modules/wawi/refinementProduct/RefinementProductFormController',
	'modules/settings/importexportcsv/ImportExportCSVController',
	'modules/settings/metadata/MetadataSetFormController',
	'modules/settings/metadata/MetadataSetListController',
	'modules/settings/metadata/MetadataFormController',
	'modules/settings/metadata/MetadataListController',
	'modules/settings/econ/EconSettingsController',
	'modules/models',
	'modules/home/controllers/HomeController',
	'modules/login/controllers/LoginController',
	'modules/changePassword/controllers/ChangePasswordController',
	'modules/image/controllers/ImageController',
	'modules/image/controllers/ImageUploadController',
	'modules/notification/controllers/NotificationController',
	'modules/navigation/controllers/NavigationController',
	'modules/shipment/controllers/ShipmentController',
	'modules/home/controllers/ReportController',
	'modules/home/controllers/SeminarDetailReportController',
], function (models) {
	'use strict';

	return {
		initRoutes: function ($urlRouterProvider, $stateProvider) {
			$urlRouterProvider.otherwise('/');

			$stateProvider

				//---===NEW===---
				.state('root.econSettings', {
					url: '/econ-settings',
					views: {
						'content@': {
							controller: 'EconSettingsController',
							templateUrl: 'template/econ/partials/settings.html',
						},
					},
				})
				.state('root', {
					url: '',
					abstract: true,
					views: {
						'navbar@': {
							controller: 'NavigationController',
							templateUrl: 'template/navigation/navbar.html',
						},
						'footer@': {
							controller: 'FooterController',
							templateUrl: 'template/navigation/footer.html',
						},
						'submenu@': {
							controller: 'NavigationController',
							templateUrl: 'template/navigation/submenu.html',
						},
					},
				})
				.state('root.tags', {
					url: '/tags',
					views: {
						'content@': {
							controller: 'TagListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.tag', {
					url: '/tag/:id',
					views: {
						'content@': {
							controller: 'TagFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.cmstags', {
					url: '/cmstags',
					views: {
						'content@': {
							controller: 'CmsTagListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.cmstag', {
					url: '/cmstag/:id',
					views: {
						'content@': {
							controller: 'CmsTagFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.productRatings', {
					url: '/product-rating/',
					views: {
						'content@': {
							controller: 'ProductRatingController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.productRating', {
					url: '/product-rating/:id',
					views: {
						'content@': {
							controller: 'ProductRatingFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.productFaqs', {
					url: '/product-faq/',
					views: {
						'content@': {
							controller: 'ProductFaqController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.productFaq', {
					url: '/product-faq/:id',
					views: {
						'content@': {
							controller: 'ProductFaqFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econDashboard', {
					url: '/econ/dashboard',
					views: {
						'content@': {
							controller: 'EconDashboardController',
							templateUrl: 'template/econ/partials/dashboard.html',
						},
					},
				})
				.state('root.econRecipients', {
					url: '/econ/recipients',
					views: {
						'content@': {
							controller: 'EconRecipientController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econRecipient', {
					url: '/econ/recipient/:id',
					views: {
						'content@': {
							controller: 'EconRecipientFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econRecipientLists', {
					url: '/econ/recipient-lists',
					views: {
						'content@': {
							controller: 'EconRecipientListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econRecipientList', {
					url: '/econ/recipient-list/:id',
					views: {
						'content@': {
							controller: 'EconRecipientListFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econMailtemplates', {
					url: '/econ/mailtemplates',
					views: {
						'content@': {
							controller: 'EconMailtemplateController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econNewsletters', {
					url: '/econ/newsletters',
					views: {
						'content@': {
							controller: 'EconNewsletterController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econNewsletter', {
					url: '/econ/newsletter/:id',
					views: {
						'content@': {
							controller: 'EconNewsletterFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econAutomations', {
					url: '/econ/automations',
					views: {
						'content@': {
							controller: 'EconAutomationController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econAutomation', {
					url: '/econ/automation/:id',
					views: {
						'content@': {
							controller: 'EconAutomationFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econMailtemplate', {
					url: '/econ/mailtemplate/:id',
					views: {
						'content@': {
							controller: 'EconMailtemplateFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.econCampaigns', {
					url: '/econ/campaigns',
					views: {
						'content@': {
							controller: 'EconCampaignController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.econCampaign', {
					url: '/econ/campaign/:id',
					views: {
						'content@': {
							controller: 'EconCampaignFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.contents', {
					url: '/contents',
					views: {
						'content@': {
							controller: 'ContentController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.content', {
					url: '/content/:id',
					views: {
						'content@': {
							controller: 'ContentFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.filterPage', {
					url: '/filter-page/:id',
					views: {
						'content@': {
							controller: 'FilterPageFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.filterPages', {
					url: '/filter-pages',
					views: {
						'content@': {
							controller: 'FilterPageController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.templatePage', {
					url: '/template-page/:id',
					views: {
						'content@': {
							controller: 'TemplatePageFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.templatePages', {
					url: '/template-pages',
					views: {
						'content@': {
							controller: 'TemplatePageController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.contentPage', {
					url: '/content-page/:id',
					views: {
						'content@': {
							controller: 'ContentPageFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.contentPages', {
					url: '/content-pages',
					views: {
						'content@': {
							controller: 'ContentPageController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.glossaryPage', {
					url: '/glossary-page/:id',
					views: {
						'content@': {
							controller: 'GlossaryPageFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.glossaryPages', {
					url: '/glossary-pages',
					views: {
						'content@': {
							controller: 'GlossaryPageController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.landingPage', {
					url: '/landing-page/:id',
					views: {
						'content@': {
							controller: 'LandingPageFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.landingPages', {
					url: '/landing-pages',
					views: {
						'content@': {
							controller: 'LandingPageController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.products', {
					url: '/products',
					views: {
						'content@': {
							controller: 'ProductController',
							templateUrl: 'template/wawi/product/product-list.html',
						},
					},
				})
				.state('root.productSimple', {
					url: '/product/simple/:id',
					views: {
						'content@': {
							controller: 'ProductFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.productBundle', {
					url: '/product/bundle/:id',
					views: {
						'content@': {
							controller: 'ProductBundleFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.productConfig', {
					url: '/product/config/:id',
					views: {
						'content@': {
							controller: 'ProductConfigFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.productWizard', {
					url: '/wizard/:id',
					views: {
						'content@': {
							controller: 'ProductWizardController',
							templateUrl: 'template/wawi/product/product-wizard.html',
						},
					},
				})
				.state('root.productWizardSimple', {
					url: '/wizard/:id/simple',
					views: {
						'content@': {
							controller: 'ProductWizardController',
							templateUrl: 'template/wawi/product/product-wizard.html',
						},
					},
				})
				.state('root.productWizardConfig', {
					url: '/wizard/:id/config',
					views: {
						'content@': {
							controller: 'ProductWizardController',
							templateUrl: 'template/wawi/product/product-wizard.html',
						},
					},
				})
				.state('root.productWizardBundle', {
					url: '/wizard/:id/bundle',
					views: {
						'content@': {
							controller: 'ProductWizardController',
							templateUrl: 'template/wawi/product/product-wizard.html',
						},
					},
				})
				.state('root.importExportCSV', {
					url: '/importExportCSV',
					views: {
						'content@': {
							controller: 'ImportExportCSVController',
							templateUrl: 'template/settings/importExport/import-export-csv.html',
						},
					},
				})
				.state('root.customers', {
					url: '/customers',
					views: {
						'content@': {
							controller: 'CustomerController',
							templateUrl: 'template/crm/customer/entity-list.html',
						},
					},
				})
				.state('root.customer', {
					url: '/customer/:id',
					views: {
						'content@': {
							controller: 'CustomerFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.organisations', {
					url: '/organisations',
					views: {
						'content@': {
							controller: 'OrganisationListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.organisation', {
					url: '/organisation/:id',
					views: {
						'content@': {
							controller: 'OrganisationFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.tickets', {
					url: '/tickets',
					views: {
						'content@': {
							controller: 'TicketListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.ticket', {
					url: '/ticket/:id',
					views: {
						'content@': {
							controller: 'TicketFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.shippinggroups', {
					url: '/shipping-groups',
					views: {
						'content@': {
							controller: 'ShippingGroupController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.shippinggroup', {
					url: '/shipping-group/:id',
					views: {
						'content@': {
							controller: 'ShippingGroupFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.shippingrules', {
					url: '/shipping-rules',
					views: {
						'content@': {
							controller: 'ShippingRuleController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.shippingglobals', {
					url: '/shipping-globals',
					views: {
						'content@': {
							controller: 'ShippingGlobalController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.shippingglobal', {
					url: '/shipping-global/:id',
					views: {
						'content@': {
							controller: 'ShippingGlobalFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.categories', {
					url: '/categories',
					views: {
						'content@': {
							controller: 'CategoryController',
							templateUrl: 'template/wawi/category/form-category-tree.html',
						},
					},
				})
				.state('root.category', {
					url: '/category/:id',
					views: {
						'content@': {
							controller: 'CategoryFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.ordermanagements', {
					url: '/ordermanagements',
					views: {
						'content@': {
							controller: 'OrderManagementController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.ordermanagement', {
					url: '/ordermanagement/:id',
					views: {
						'content@': {
							controller: 'OrderManagementFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.ordermanagementReorder', {
					url: '/ordermanagement-reorder/:shoppingCartId',
					views: {
						'content@': {
							controller: 'OrderManagementFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.ordermanagementsetting', {
					url: '/ordermanagementsetting/:id',
					views: {
						'content@': {
							controller: 'OrderManagementSettingsFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.attributes', {
					url: '/attributes',
					views: {
						'content@': {
							controller: 'AttributeController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})

				.state('root.attribute', {
					url: '/attribute/:id',
					views: {
						'content@': {
							controller: 'AttributeFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.imexprofiles', {
					url: '/imexprofiles',
					views: {
						'content@': {
							controller: 'ImExProfilesController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.imexprofile', {
					url: '/imexprofile/:id',
					views: {
						'content@': {
							controller: 'ImExProfilesFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.serverprofiles', {
					url: '/serverprofiles',
					views: {
						'content@': {
							controller: 'ServerProfilesController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.serverprofile', {
					url: '/serverprofil/:id',
					views: {
						'content@': {
							controller: 'ServerProfilesFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.users', {
					url: '/users',
					views: {
						'content@': {
							controller: 'UserController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.user', {
					url: '/user/:id',
					views: {
						'content@': {
							controller: 'UserFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.logs', {
					url: '/logs',
					views: {
						'content@': {
							controller: 'LogController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.systemInformation', {
					url: '/system-information',
					views: {
						'content@': {
							controller: 'SystemInformationController',
							templateUrl: 'template/settings/systemInformation/form-main.html',
						},
					},
				})
				.state('root.smtp', {
					url: '/smtp',
					views: {
						'content@': {
							controller: 'SmtpController',
							templateUrl: 'template/settings/smtp/form-smtp.html',
						},
					},
				})
				.state('root.countries', {
					url: '/countries',
					views: {
						'content@': {
							controller: 'CountryController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.country', {
					url: '/country/:id',
					views: {
						'content@': {
							controller: 'CountryFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.shops', {
					url: '/shops',
					views: {
						'content@': {
							controller: 'ShopController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.shop', {
					url: '/shop/:id',
					views: {
						'content@': {
							controller: 'ShopFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.taxes', {
					url: '/taxes',
					views: {
						'content@': {
							controller: 'TaxController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.tax', {
					url: '/tax/:id',
					views: {
						'content@': {
							controller: 'TaxFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.dataFeedInputs', {
					url: '/data-feed-inputs',
					views: {
						'content@': {
							controller: 'DataFeedInputController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.dataFeedInput', {
					url: '/data-feed-input/:id',
					views: {
						'content@': {
							controller: 'DataFeedInputFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.currencies', {
					url: '/currencies',
					views: {
						'content@': {
							controller: 'CurrencyController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.currency', {
					url: '/currency/:id',
					views: {
						'content@': {
							controller: 'CurrencyFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.bankAccounts', {
					url: '/bank-accounts',
					views: {
						'content@': {
							controller: 'BankAccountController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.bankAccount', {
					url: '/bank-account/:id',
					views: {
						'content@': {
							controller: 'BankAccountFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.customergroups', {
					url: '/customergroups',
					views: {
						'content@': {
							controller: 'CustomerGroupController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.customergroup', {
					url: '/customergroup/:id',
					views: {
						'content@': {
							controller: 'CustomerGroupFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.units', {
					url: '/units',
					views: {
						'content@': {
							controller: 'UnitController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.unit', {
					url: '/unit/:id',
					views: {
						'content@': {
							controller: 'UnitFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.nodes', {
					url: '/nodes',
					views: {
						'content@': {
							controller: 'NodeController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.node', {
					url: '/node/:id',
					views: {
						'content@': {
							controller: 'NodeFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.seminars', {
					url: '/seminars',
					views: {
						'content@': {
							controller: 'SeminarListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.seminar', {
					url: '/seminar/:id',
					views: {
						'content@': {
							controller: 'SeminarFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.refinementProducts', {
					url: '/refinement-products',
					views: {
						'content@': {
							controller: 'RefinementProductListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.refinementProduct', {
					url: '/refinement-product/:id',
					views: {
						'content@': {
							controller: 'RefinementProductFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.branches', {
					url: '/branches',
					views: {
						'content@': {
							controller: 'BranchListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.branch', {
					url: '/branch/:id',
					views: {
						'content@': {
							controller: 'BranchFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.systemMessages', {
					url: '/systemmessages',
					views: {
						'content@': {
							controller: 'SystemMessageListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.cache', {
					url: '/cache',
					views: {
						'content@': {
							controller: 'CacheController',
							templateUrl: 'template/settings/cache/form-main.html',
						},
					},
				})
				.state('root.backup', {
					url: '/backup',
					views: {
						'content@': {
							controller: 'BackupController',
							templateUrl: 'template/settings/backup/backup.html',
						},
					},
				})
				.state('root.systemMessage', {
					url: '/systemmessage/:id',
					views: {
						'content@': {
							controller: 'SystemMessageFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.classifications', {
					url: '/classifications',
					views: {
						'content@': {
							controller: 'ClassificationListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.classification', {
					url: '/classification/:id',
					views: {
						'content@': {
							controller: 'ClassificationFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.metadatasets', {
					url: '/metadatasets',
					views: {
						'content@': {
							controller: 'MetadataSetListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.metadataset', {
					url: '/metadataset/:id',
					views: {
						'content@': {
							controller: 'MetadataSetFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.metadatas', {
					url: '/metadatas',
					views: {
						'content@': {
							controller: 'MetadataListController',
							templateUrl: 'template/settings/metadata/metadata-list.html',
						},
					},
				})
				.state('root.metadata', {
					url: '/metadata/:id',
					views: {
						'content@': {
							controller: 'MetadataFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.couponcodes', {
					url: '/couponcodes',
					views: {
						'content@': {
							controller: 'CouponCodeListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.couponcode', {
					url: '/couponcode/:id',
					views: {
						'content@': {
							controller: 'CouponCodeFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				.state('root.pricerules', {
					url: '/pricerules',
					views: {
						'content@': {
							controller: 'PriceRuleListController',
							templateUrl: 'template/basemodule/entity-list.html',
						},
					},
				})
				.state('root.pricerule', {
					url: '/pricerule/:id',
					views: {
						'content@': {
							controller: 'PriceRuleFormController',
							templateUrl: 'template/basemodule/entity-form.html',
						},
					},
				})
				//---===OLD===---
				.state('root.home', {
					url: '/',
					views: {
						'content@': {
							controller: 'HomeController',
							templateUrl: 'template/home/home.html',
						},
					},
				})
				.state('root.reports', {
					url: '/reports/:reportType/:startDate/:endDate',
					views: {
						'content@': {
							controller: 'ReportController',
							templateUrl: 'template/home/reports.html',
							params: { reportType: 'topProducts', startDate: null, endDate: null },
						},
					},
				})
				.state('root.seminarDetailReport', {
					url: '/seminarDetailReport/:id',
					views: {
						'content@': {
							controller: 'SeminarDetailReportController',
							templateUrl: 'template/home/report/seminar-detail-report.html',
						},
					},
				})
				.state('root.resetPassword', {
					url: '/resetpassword/:resetKey',
					views: {
						'content@': {
							controller: 'ChangePasswordController',
							templateUrl: 'template/login/login.html',
						},
					},
				})
				.state('root.login', {
					url: '/login',
					views: {
						'content@': {
							controller: 'LoginController',
							templateUrl: 'template/login/login.html',
						},
					},
				})
				.state('root.images', {
					url: '/images',
					views: {
						'content@': {
							controller: 'ImageUploadController',
							templateUrl: 'template/image/upload.html',
						},
					},
				})
				.state('root.shipment', {
					url: '/shipment',
					views: {
						'content@': {
							controller: 'ShipmentController',
							templateUrl: 'template/shipment/shipment.html',
						},
					},
				})
				.state('about', {
					url: '/about',
					templateUrl: '/nix.html',
					controller: function ($scope) {
						$scope.nix = 'ABOUT DIALOG';
						$scope.show = !$scope.show;
					},
				});
		},
	};
});

