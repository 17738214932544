/**
 * Created by antarya on 12.07.2019.
 */
define('modules/crm/ticket/controllers/TicketListController',['modules/module', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('TicketListController', ['$controller', '$scope', 'BASE_TEMPLATES_PATH', TicketListController]);
});
function TicketListController($controller, $scope, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'DATE',
			heading: 'FIELDS.DATE',
			attribute: 'createdAt',
			searchDisabled: true,
		},
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'name',
		},
		{
			id: 'STATUS',
			heading: 'FIELDS.STATUS',
			attribute: 'status',
		},
		{
			id: 'AUTHOR',
			heading: 'FIELDS.AUTHOR',
			attribute: 'author',
		},
		{
			id: 'CUSTOMER',
			heading: 'FIELDS.CUSTOMER',
			attribute: 'customerFullName',
			searchDisabled: true,
			orderDisabled: true,
		},
		{
			id: 'COMMUNICATIONS',
			heading: 'FIELDS.COMMUNICATION',
			template: BASE_TEMPLATES_PATH + '/crm/ticket/ticket-communications-col.html',
			searchDisabled: true,
			orderDisabled: true,
			class: 'text-right',
			attribute: '',
		},
	];

	$scope.formState = 'root.ticket';

	$scope.init('Ticket');
}
;
