/**
 * Created by Nico on 07.07.2016.
 */
define('services/ShopService',[
	'app',
	'modules/module',
	'services/EntityCrudService',
	'services/LocalStorageService',
	'services/ShippingMethodUnpagedService',
], function (app, module) {
	'use strict';
	return module.factory('ShopService', [
		'$injector',
		'$q',
		'ModelFactory',
		'localStorageService',
		'ShippingMethodUnpagedService',
		ShopService,
	]);
});

function ShopService($injector, $q, ModelFactory, localStorageService, ShippingMethodUnpagedService) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Shop');

	angular.extend(this, crudInstance);

	let pluginSettingTemplates;

	this.findShop = function () {
		var currentShop = localStorageService.get('_shop');
		if (currentShop == undefined || currentShop == null) {
			currentShop = -1;
		}

		return this.findOne(currentShop);
	};

	/**
	 * TODO: Add 'get first shop' request to backend
	 * @returns promise
	 */
	this.findAnyShop = () => {
		const deferred = $q.defer();
		ModelFactory.Shop()
			.findAll({ page: 0, size: 10000000 })
			.$promise.then((response) => {
				const shops = response.data;
				let hasDefault = false;
				if (shops.length > 0) {
					for (var i = 0; i < shops.length; i++) {
						if (shops[i].isDefault === true) {
							hasDefault = true;
							deferred.resolve(shops[i]);
						}
					}
					if (!hasDefault) {
						var shopId = shops.reduce(function (res, obj) {
							return obj.id < res.id ? obj : res;
						});
						deferred.resolve(shopId);
					}
				} else {
					deferred.reject();
				}
			});

		return deferred.promise;
	};

	this.generateSitemap = function () {
		var currentShop = localStorageService.get('_shop');
		if (currentShop == undefined || currentShop == null) {
			currentShop = -1;
		}
		var model = new $injector.get('ModelFactory').SeoSettings();
		return model.generateSitemap({ id: currentShop });
	};

	this.downloadSitemap = function () {
		var currentShop = localStorageService.get('_shop');
		if (currentShop == undefined || currentShop == null) {
			currentShop = -1;
		}
		var model = new $injector.get('ModelFactory').SeoSettings();
		return model.downloadSiteMap({ id: currentShop });
	};

	this.shippingMethods = function (entity) {
		if (entity.shopShippingMethods == undefined) {
			ShippingMethodUnpagedService.findAll().then(function () {
				entity.shopShippingMethods = ShippingMethodUnpagedService.getAll(true).data;
				for (var i = 0; i < entity.shopShippingMethods.length; i++) {
					entity.shopShippingMethods[i].isActive = false;
					entity.shopShippingMethods[i].isDefault = false;
					entity.shopShippingMethods[i].sortOrder = 99;

					for (var j = 0; j < entity.shopShippingMethods[i].deliveryServices.length; j++) {
						entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod = {
							deliveryMethodType: '',
							deliveryMethodConfigurations: [],
						};
					}
				}
			});
		} else {
			for (var i = 0; i < entity.shopShippingMethods.length; i++) {
				for (var j = 0; j < entity.shopShippingMethods[i].deliveryServices.length; j++) {
					if (entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod == undefined) {
						entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod = {
							deliveryMethodType: '',
							deliveryMethodConfigurations: [],
						};
					} else if (
						entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodType ==
						'DESTINATION'
					) {
						entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.countryConfigurations =
							entity.shopShippingMethods[i].deliveryServices[
								j
							].deliveryMethod.deliveryMethodConfigurations;
					} else if (
						entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodType ==
						'SHOPPINGCARTWEIGHT'
					) {
						var weightConfigs = [];

						if (
							entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
								.deliveryMethodConfigurations.length > 0
						) {
							for (
								var k = 0;
								k <
								entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
									.deliveryMethodConfigurations.length;
								k++
							) {
								var countryId =
									entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
										.deliveryMethodConfigurations[k].country.id;
								var groupId =
									entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
										.deliveryMethodConfigurations[k].groupId;
								var valueId =
									entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
										.deliveryMethodConfigurations[k].valueId;

								var weightConf = {};
								var countries = [];
								var configs = [];

								if (k == 0) {
									weightConf['groupId'] = groupId;
									countries.push(countryId);
									weightConf['countries'] = countries;
									configs.push(
										entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
											.deliveryMethodConfigurations[k]
									);
									weightConf['configurations'] = configs;
									weightConfigs.push(weightConf);
								} else {
									var groupExist = false;
									for (var l = 0; l < weightConfigs.length; l++) {
										if (weightConfigs[l].groupId == groupId) {
											groupExist = true;

											if (groupExist) {
												if (weightConfigs[l].countries.indexOf(countryId) == -1) {
													weightConfigs[l].countries.push(countryId);
												}
												var valIdExist = false;
												for (var m = 0; m < weightConfigs[l].configurations.length; m++) {
													if (weightConfigs[l].configurations[m].valueId == valueId) {
														valIdExist = true;
													}
												}
												if (!valIdExist) {
													weightConfigs[l].configurations.push(
														entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
															.deliveryMethodConfigurations[k]
													);
												}
											}
										}
									}
									if (!groupExist) {
										weightConf['groupId'] = groupId;
										countries.push(countryId);
										weightConf['countries'] = countries;
										configs.push(
											entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
												.deliveryMethodConfigurations[k]
										);
										weightConf['configurations'] = configs;
										weightConfigs.push(weightConf);
									}
								}
							}

							entity.shopShippingMethods[i].deliveryServices[j].weightConfigs = weightConfigs;
						}
					} else if (
						entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodType ==
						'SHOPPINGCARTVOLUME'
					) {
						var volumeConfigs = [];

						if (
							entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
								.deliveryMethodConfigurations.length > 0
						) {
							for (
								var k = 0;
								k <
								entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
									.deliveryMethodConfigurations.length;
								k++
							) {
								var countryId =
									entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
										.deliveryMethodConfigurations[k].country.id;
								var groupId =
									entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
										.deliveryMethodConfigurations[k].groupId;
								var valueId =
									entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
										.deliveryMethodConfigurations[k].valueId;

								var volumeConf = {};
								var countries = [];
								var configs = [];

								if (k == 0) {
									volumeConf['groupId'] = groupId;
									countries.push(countryId);
									volumeConf['countries'] = countries;
									configs.push(
										entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
											.deliveryMethodConfigurations[k]
									);
									volumeConf['configurations'] = configs;
									volumeConfigs.push(volumeConf);
								} else {
									var groupExist = false;
									for (var l = 0; l < volumeConfigs.length; l++) {
										if (volumeConfigs[l].groupId == groupId) {
											groupExist = true;

											if (groupExist) {
												if (volumeConfigs[l].countries.indexOf(countryId) == -1) {
													volumeConfigs[l].countries.push(countryId);
												}
												var valIdExist = false;
												for (var m = 0; m < volumeConfigs[l].configurations.length; m++) {
													if (volumeConfigs[l].configurations[m].valueId == valueId) {
														valIdExist = true;
													}
												}
												if (!valIdExist) {
													volumeConfigs[l].configurations.push(
														entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
															.deliveryMethodConfigurations[k]
													);
												}
											}
										}
									}
									if (!groupExist) {
										volumeConf['groupId'] = groupId;
										countries.push(countryId);
										volumeConf['countries'] = countries;
										configs.push(
											entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
												.deliveryMethodConfigurations[k]
										);
										volumeConf['configurations'] = configs;
										volumeConfigs.push(volumeConf);
									}
								}
							}

							entity.shopShippingMethods[i].deliveryServices[j].volumeConfigs = volumeConfigs;
						}
					} else if (
						entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod.deliveryMethodType ==
						'SHOPPINGCARTWEIGHTVOLUME'
					) {
						var weightVolumeConfigs = [];

						if (
							entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
								.deliveryMethodConfigurations.length > 0
						) {
							for (
								var k = 0;
								k <
								entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
									.deliveryMethodConfigurations.length;
								k++
							) {
								var countryId =
									entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
										.deliveryMethodConfigurations[k].country.id;
								var groupId =
									entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
										.deliveryMethodConfigurations[k].groupId;
								var valueId =
									entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
										.deliveryMethodConfigurations[k].valueId;

								var weightVolumeConf = {};
								var countries = [];
								var configs = [];

								if (k == 0) {
									weightVolumeConf['groupId'] = groupId;
									countries.push(countryId);
									weightVolumeConf['countries'] = countries;
									configs.push(
										entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
											.deliveryMethodConfigurations[k]
									);
									weightVolumeConf['configurations'] = configs;
									weightVolumeConfigs.push(weightVolumeConf);
								} else {
									var groupExist = false;
									for (var l = 0; l < weightVolumeConfigs.length; l++) {
										if (weightVolumeConfigs[l].groupId == groupId) {
											groupExist = true;

											if (groupExist) {
												if (weightVolumeConfigs[l].countries.indexOf(countryId) == -1) {
													weightVolumeConfigs[l].countries.push(countryId);
												}
												var valIdExist = false;
												for (var m = 0; m < weightVolumeConfigs[l].configurations.length; m++) {
													if (weightVolumeConfigs[l].configurations[m].valueId == valueId) {
														valIdExist = true;
													}
												}
												if (!valIdExist) {
													weightVolumeConfigs[l].configurations.push(
														entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
															.deliveryMethodConfigurations[k]
													);
												}
											}
										}
									}
									if (!groupExist) {
										weightVolumeConf['groupId'] = groupId;
										weightVolumeConf['countries'] = countries;
										configs.push(
											entity.shopShippingMethods[i].deliveryServices[j].deliveryMethod
												.deliveryMethodConfigurations[k]
										);
										weightVolumeConf['configurations'] = configs;
										weightVolumeConfigs.push(weightVolumeConf);
									}
								}
							}

							entity.shopShippingMethods[i].deliveryServices[j].weightVolumeConfigs = weightVolumeConfigs;
						}
					}
				}
			}
		}

		return entity;
	};

	this.fetchPluginSettingTemplates = function (id) {
		const deferred = $q.defer();
		ModelFactory.PluginSettingTemplates()
			.findAll({ shopId: id })
			.$promise.then((response) => {
				pluginSettingTemplates = response;
				deferred.resolve(response);
			});

		return deferred.promise;
	};

	this.pluginSettingTemplates = function () {
		return pluginSettingTemplates;
	};

	return this;
}
;
