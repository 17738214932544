define('modules/wawi/category/controllers/tabs/CategoryProductsTabController',['modules/module', 'modules/wawi/product/controllers/ProductController'], function (module) {
	'use strict';
	module.controller('CategoryProductsTabController', [
		'$scope',
		'$rootScope',
		'$controller',
		'$attrs',
		'$timeout',
		'$injector',
		'$q',
		'BASE_TEMPLATES_PATH',
		'ProductService',
		CategoryProductsTabController,
	]);
});

function CategoryProductsTabController(
	$scope,
	$rootScope,
	$controller,
	$attrs,
	$timeout,
	$injector,
	$q,
	BASE_TEMPLATES_PATH,
	ProductService
) {
	$scope.init = function (id) {
		$scope.localParams = [
			{
				name: 'selectCategoryId',
				value: id,
			},
		];

		angular.extend(
			this,
			$controller('ProductController', {
				$scope: $scope,
			})
		);
		$scope.CONTROLLER_ID = 'CategoryProductsTabController';

		$scope.topBarTemplate = BASE_TEMPLATES_PATH + '/wawi/product/top-bar-template-category-products.html';

		$scope.preserveForDialogs = function (item, field) {
			return field.id !== 'SORT_ORDER' && field.id !== 'CATEGORY_SORT_ORDER';
		};

		$scope.$on('saveCategorySortOrderFromTable', function (e, mainEvent, data) {
			const code = mainEvent ? mainEvent.keyCode : false;
			if (!code || (code >= 48 && code <= 57) || code === 38 || code === 40 || code === 8) {
				if ($scope.delay !== undefined) {
					$timeout.cancel($scope.delay);
				}
				$scope.delay = $timeout(function () {
					var flag;
					var index;
					for (index = 0; index < $scope.productsToSave.length; ++index) {
						if ($scope.productsToSave[index].id === data.id) {
							$scope.productsToSave[index] = data;
							flag = true;
						}
					}
					if (!flag) $scope.productsToSave.push(data);

					$scope.delay = undefined;
				}, 800);
			}
		});

		$scope.tableOptions = {};
		$scope.tableOptions.fields = [
			{
				id: 'SORT_ORDER',
				heading: 'FIELDS.SORTING',
				attribute: 'sortOrder',
				template: BASE_TEMPLATES_PATH + '/wawi/product/col/sort-order-col.html',
			},
			{
				id: 'CATEGORY_SORT_ORDER',
				heading: 'FIELDS.CATEGORY_SORTING',
				attribute: 'categorySortOrder',
				template: BASE_TEMPLATES_PATH + '/wawi/product/col/category-sort-order-col.html',
			},
			{
				id: 'THUMB_IMG',
				heading: 'FIELDS.IMAGE',
				template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-thumb-col.html',
				searchDisabled: true,
				orderDisabled: true,
			},
			{
				id: 'STATUS',
				heading: 'FIELDS.STATUS',
				attribute: 'publishState',
				searchDisabled: true,
			},
			{
				id: 'PRODUCT_TYPE',
				heading: 'FIELDS.PRODUCT_TYPE',
				attribute: 'productType',
				searchDisabled: true,
			},
			{
				id: 'NAME',
				heading: 'FIELDS.NAME',
				template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-name-col.html',
				attribute: 'name',
			},
			{
				id: 'SKU',
				heading: 'FIELDS.SKU',
				attribute: 'sku',
			},
			{
				id: 'SLUG',
				heading: 'FIELDS.SLUG',
				attribute: 'slug',
			},
		];

		$scope.saveSortOrder = function () {
			ProductService.updateCategorySortOrder($scope.productsToSave, id);
		};
	};
}
;
