define('modules/settings/shop/tabs/PaymentMethodTabController',[], function () {
	'use strict';

	return function PaymentMethodController(
		$parentScope,
		$scope,
		{ BASE_TEMPLATES_PATH, NotificationService, PaymentMethodService }
	) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.PAYMENT_METHOD',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-payment-methods.html',
		});

		const doesShopPaymentMethodExist = (paymentMethodType) => {
			return $parentScope.entity.shopPaymentMethods.find((spm) => spm.type === paymentMethodType) !== undefined;
		};

		const getPaymentMethodById = (id) => {
			if (!id) {
				return null;
			}

			return $parentScope.data.paymentMethods.find((pm) => pm.id === id);
		};

		$scope.addPaymentMethodToShop = () => {
			const paymentMethod = getPaymentMethodById($parentScope.data.selectedPaymentMethodId);
			if (!paymentMethod) {
				return;
			}

			if (doesShopPaymentMethodExist(paymentMethod.paymentMethodType)) {
				NotificationService.notifyWarning({ statusText: 'Zahlungsmethode wird bereits verwendet' });
				return;
			}

			if (!$parentScope.entity.shopPaymentMethods) {
				$parentScope.entity.shopPaymentMethods = [];
			}

			$parentScope.entity.shopPaymentMethods.push({
				description: paymentMethod.description,
				developMode: false,
				isActive: true,
				isDefault: false,
				name: paymentMethod.name,
				paymentMethodId: paymentMethod.id,
				sortOrder: 9999,
				type: paymentMethod.paymentMethodType,
			});
		};

		$scope.deleteShopPaymentMethod = (index) => {
			$parentScope.entity.shopPaymentMethods.splice(index, 1);
		};

		$scope.initPaymentMethods = () => {
			$parentScope.entity.shopPaymentMethods = $parentScope.entity.shopPaymentMethods || [];

			if ($parentScope.data.paymentMethods.length) {
				return;
			}

			PaymentMethodService.findAll(true).then((response) => {
				$parentScope.data.paymentMethods = response.data;
			});
		};

		$scope.setDefaultShopPaymentMethod = (index) => {
			for (let i = 0; i < $parentScope.entity.shopPaymentMethods.length; i++) {
				$parentScope.entity.shopPaymentMethods[i].isDefault = i === index;
			}
		};

		$scope.validate = (pm) => {
			let index = $parentScope.entity.shopPaymentMethods.indexOf(
				$parentScope.entity.shopPaymentMethods.find((x) => x.type === pm)
			);
			if (index >= 0) {
				let method = $parentScope.entity.shopPaymentMethods[index];
				switch (pm) {
					case 'PAYPAL_PLUS_PAYMENT':
						return {
							liveClientId: {
								method: 'isset',
								fieldName: `${method.description} Live Client Id`,
								customValidate: function () {
									return !!method.liveClientId;
								},
							},
							liveSecretId: {
								method: 'isset',
								fieldName: `${method.description} Live Secret Id`,
								customValidate: function () {
									return !!method.liveSecretId;
								},
							},
							sandboxClientId: {
								method: 'isset',
								fieldName: `${method.description} Sandbox Client Id`,
								customValidate: function () {
									return !!method.sandboxClientId;
								},
							},
							sandboxSecretId: {
								method: 'isset',
								fieldName: `${method.description} Sandbox Secret Id`,
								customValidate: function () {
									return !!method.sandboxSecretId;
								},
							},
							shopCancelUrl: {
								method: 'isset',
								fieldName: `${method.description} Shop Cancel URL`,
								customValidate: function () {
									return !!method.shopCancelUrl;
								},
							},
							shopReturnUrl: {
								method: 'isset',
								fieldName: `${method.description} Shop Return URL`,
								customValidate: function () {
									return !!method.shopReturnUrl;
								},
							},
							shopUrl: {
								method: 'isset',
								fieldName: `${method.description} Shop URL`,
								customValidate: function () {
									return !!method.shopUrl;
								},
							},
						};
					case 'SOFORT_PAYMENT':
						return {
							customerId: {
								method: 'isset',
								fieldName: `${method.description}  Customer Id`,
								customValidate: function () {
									return !!method.customerId;
								},
							},
							projectId: {
								method: 'isset',
								fieldName: `${method.description} Project Id`,
								customValidate: function () {
									return !!method.projectId;
								},
							},
							shopCancelUrl: {
								method: 'isset',
								fieldName: `${method.description} Shop Cancel URL`,
								customValidate: function () {
									return !!method.shopCancelUrl;
								},
							},
							shopReturnUrl: {
								method: 'isset',
								fieldName: `${method.description} Shop Return URL`,
								customValidate: function () {
									return !!method.shopReturnUrl;
								},
							},
							apiKey: {
								method: 'isset',
								fieldName: `${method.description} Api Key`,
								customValidate: function () {
									return !!method.apiKey;
								},
							},
						};
				}
			}

			return {};
		};
	};
});

