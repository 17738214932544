define('modules/settings/shop/ShopFormController',[
	'modules/module',
	'modules/settings/shop/tabs/GeneralTabController',
	'modules/settings/shop/tabs/ImagesTabController',
	'modules/settings/shop/tabs/CurrenciesTabController',
	'modules/settings/shop/tabs/LocaleTabController',
	'modules/settings/shop/tabs/PaymentMethodTabController',
	'modules/settings/shop/tabs/ShippingMethodTabController',
	'modules/settings/shop/tabs/SlugSettingsTabController',
	'modules/settings/shop/tabs/B2BTabController',
	'modules/settings/shop/tabs/JLSettingsTabController',
	'modules/settings/shop/tabs/LegalTextsTabController',
	'modules/settings/shop/tabs/GlobalSettingsTabController',
	'modules/settings/shop/tabs/SeoSettingsTabController',
	'modules/settings/shop/tabs/SearchSettingsTabController',
	'modules/settings/shop/tabs/SystemMessagesTabContrioller',
	'modules/settings/shop/tabs/CopyProductsTabController',
	'modules/settings/shop/tabs/MediaTabController',
	'modules/settings/shop/tabs/PluginSettingsTabController',
	'modules/settings/shop/tabs/PaymentPolicyTabController',
	'modules/settings/shop/tabs/SmtpTabController',
	'modules/settings/shop/tabs/DropShipmentTabController',
	'modules/settings/shop/tabs/ShippingLabelTabController',
	'modules/basemodule/controllers/EntityFormController',
	'services/CustomerGroupService',
	'services/PaymentPolicyService',
	'services/PublishStateService',
	'services/CurrencyService',
	'services/LocaleService',
	'services/PaymentMethodService',
	'services/ShippingMethodService',
	'services/ShippingMethodUnpagedService',
	'services/CountryService',
	'services/CountryUnpagedService',
	'services/ShopService',
	'services/DropShippingService',
	'services/OrderStateUnpagedService',
	'services/ImageService',
	'services/ValidationService',
	'services/NotificationService',
	'services/CopyProductsService',
	'services/NavigationService',
	'services/EconSettingService',
	'services/TaxService',
	'services/MetadataUnpagedService',
	'services/AttributeUnpagedService',
	'services/CategoryService',
	'services/LocalStorageService',
	'services/UnitService',
	'services/OrganisationService',
	'services/ProductUnpagedService',
	'services/BankAccountService',
	'services/DebounceService',
	'services/OrderManagementService',
	'services/SystemMessageUnpagedService',
	'services/UserService',
	'services/EconRecipientListService',
	'directives/dndList/dir-dnd',
	'services/SessionValuesService',
	'services/RuleService',
	'settings',
	'directives/controls/customTextarea/CustomTextarea',
	'directives/mediaUpload/mediaUpload',
	'directives/mediaList/mediaList',
], function (
	module,
	generalTab,
	imagesTab,
	currenciesTab,
	localeTab,
	paymentMethodTab,
	shippingMethodTab,
	slugSettingsTab,
	B2BTab,
	JLSettingsTab,
	legalTextsTab,
	globalSettingsTab,
	seoSettingsTab,
	searchSettingsTab,
	systemMessagesTab,
	copyProductsTab,
	mediaTab,
	pluginSettingsTab,
	paymentPolicyTab,
	smtpTab,
	dropShipmentTab,
	shippingLabelTab
) {
	'use strict';
	module.controller('ShopFormController', [
		'ProductService',
		'$scope',
		'$injector',
		'$translate',
		'OrderStateUnpagedService',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'TaxService',
		'NotificationService',
		'CopyProductsService',
		'NavigationService',
		'CustomerGroupService',
		'PublishStateService',
		'CurrencyService',
		'LocaleService',
		'PaymentMethodService',
		'CountryService',
		'ShippingRuleService',
		'CountryUnpagedService',
		'ShippingMethodService',
		'ShippingMethodUnpagedService',
		'ShopService',
		'ImageService',
		'ValidationService',
		'ShippingLabelService',
		'EconSettingService',
		'DropShippingService',
		'AttributeUnpagedService',
		'CategoryService',
		'LocalStorageService',
		'MetadataUnpagedService',
		'UnitService',
		'OrganisationService',
		'$window',
		'SessionValuesService',
		'BankAccountService',
		'DebounceService',
		'ProductUnpagedService',
		'$rootScope',
		'OrderManagementService',
		'SystemMessageService',
		'PRODUCT_FILTER_OPTIONS',
		'SystemMessageUnpagedService',
		'UserService',
		'EconRecipientListService',
		'PaymentPolicyService',
		'RuleService',
		'MetadataService',
		ShopFormController(
			generalTab,
			imagesTab,
			currenciesTab,
			localeTab,
			paymentMethodTab,
			shippingMethodTab,
			slugSettingsTab,
			B2BTab,
			JLSettingsTab,
			legalTextsTab,
			globalSettingsTab,
			seoSettingsTab,
			searchSettingsTab,
			systemMessagesTab,
			copyProductsTab,
			mediaTab,
			pluginSettingsTab,
			paymentPolicyTab,
			smtpTab,
			dropShipmentTab,
			shippingLabelTab
		),
	]);
});

function ShopFormController(
	generalTab,
	imagesTab,
	currenciesTab,
	localeTab,
	paymentMethodTab,
	shippingMethodTab,
	slugSettingsTab,
	B2BTab,
	JLSettingsTab,
	legalTextsTab,
	globalSettingsTab,
	seoSettingsTab,
	searchSettingsTab,
	systemMessagesTab,
	copyProductsTab,
	mediaTab,
	pluginSettingsTab,
	paymentPolicyTab,
	smtpTab,
	dropShipmentTab,
	shippingLabelTab
) {
	return function (
		ProductService,
		$scope,
		$injector,
		$translate,
		OrderStateUnpagedService,
		$controller,
		BASE_TEMPLATES_PATH,
		TaxService,
		NotificationService,
		CopyProductsService,
		NavigationService,
		CustomerGroupService,
		PublishStateService,
		CurrencyService,
		LocaleService,
		PaymentMethodService,
		CountryService,
		ShippingRuleService,
		CountryUnpagedService,
		ShippingMethodService,
		ShippingMethodUnpagedService,
		ShopService,
		ImageService,
		ValidationService,
		ShippingLabelService,
		EconSettingService,
		DropShippingService,
		AttributeUnpagedService,
		CategoryService,
		LocalStorageService,
		MetadataUnpagedService,
		UnitService,
		OrganisationService,
		$window,
		SessionValuesService,
		BankAccountService,
		DebounceService,
		ProductUnpagedService,
		$rootScope,
		OrderManagementService,
		SystemMessageService,
		PRODUCT_FILTER_OPTIONS,
		SystemMessageUnpagedService,
		UserService,
		RecipientListService,
		PaymentPolicyService,
		RuleService,
		MetadataService
	) {
		angular.extend(
			this,
			$controller('EntityFormController', {
				$scope: $scope,
			})
		);

		const injectionContext = {
			ProductService,
			OrderStateUnpagedService,
			BASE_TEMPLATES_PATH,
			$translate,
			TaxService,
			ShopService,
			NotificationService,
			CopyProductsService,
			NavigationService,
			CustomerGroupService,
			PublishStateService,
			CurrencyService,
			LocaleService,
			$window,
			PaymentMethodService,
			CountryService,
			ShippingRuleService,
			CountryUnpagedService,
			ShippingMethodService,
			ShippingMethodUnpagedService,
			ShopService,
			ImageService,
			ValidationService,
			ShippingLabelService,
			EconSettingService,
			DropShippingService,
			AttributeUnpagedService,
			CategoryService,
			LocalStorageService,
			MetadataUnpagedService,
			UnitService,
			OrganisationService,
			SessionValuesService,
			BankAccountService,
			DebounceService,
			ProductUnpagedService,
			OrderManagementService,
			SystemMessageService,
			PRODUCT_FILTER_OPTIONS,
			SystemMessageUnpagedService,
			UserService,
			RecipientListService,
			PaymentPolicyService,
			RuleService,
			MetadataService,
		};

		$scope.formTabs = [];

		$scope.adminFormTabs = [];

		generalTab($scope, ($scope.generalScope = {}), injectionContext);
		imagesTab($scope, ($scope.imagesScope = {}), injectionContext);
		currenciesTab($scope, ($scope.currenciesScope = {}), injectionContext);
		localeTab($scope, ($scope.localeScope = {}), injectionContext);
		paymentMethodTab($scope, ($scope.paymentMethodScope = {}), injectionContext);
		shippingMethodTab($scope, ($scope.shippingMethodScope = {}), injectionContext);
		slugSettingsTab($scope, ($scope.slugSettingsScope = {}), injectionContext);
		B2BTab($scope, ($scope.B2BScope = {}), injectionContext);
		JLSettingsTab($scope, ($scope.JLSettingsScope = {}), injectionContext);
		globalSettingsTab($scope, ($scope.globalSettingsScope = {}), injectionContext);
		legalTextsTab($scope, ($scope.legalTextsScope = {}), injectionContext);
		seoSettingsTab($scope, ($scope.seoSettingsScope = {}), injectionContext);
		searchSettingsTab($scope, ($scope.searchSettingsScope = {}), injectionContext);
		systemMessagesTab($scope, ($scope.systemMessagesScope = {}), injectionContext);
		copyProductsTab($scope, $rootScope, ($scope.copyProductsScope = {}), injectionContext);
		mediaTab($scope, ($scope.mediaScope = {}), injectionContext);
		pluginSettingsTab($scope, ($scope.pluginSettingsScope = {}), injectionContext);
		paymentPolicyTab($scope, ($scope.paymentPolicyScope = {}), injectionContext);
		smtpTab($scope, ($scope.smtpScope = {}), injectionContext);

		$scope.$watch(
			'localeScope.selectedLocales',
			function (e) {
				$scope.legalTextsScope.onSelectedShopLocale($scope.localeScope.selectedLocales);
			},
			true
		);

		$scope.$on('filtersChanges', function (e, filters, sorting, id) {
			if ($scope.getService() != undefined) {
				$scope
					.getService()
					.setQuery({
						filters: filters,
						sorting: sorting,
					})
					.then(function () {
						$scope.localeScope.markSelectedLocales();
					});
			}
		});

		$scope.getService = function () {
			for (var i = 0; i < $scope.formTabs.length; i++) {
				if ($scope.formTabs[i].active == true) {
					return $scope.formTabs[i].service;
				}
			}
		};

		$scope.validate = function () {
			console.log('ENTERED');
			$scope.entity.email = $scope.entity.shopConfiguration.email;
			$scope.entity.companyName = $scope.entity.shopConfiguration.companyName;

			const rules = Object.assign(
				{},
				$scope.shippingMethodScope.validate(), // TODO make generic for all scopes?
				$scope.pluginSettingsScope.validate(),
				$scope.paymentMethodScope.validate('PAYPAL_PLUS_PAYMENT'),
				$scope.paymentMethodScope.validate('SOFORT_PAYMENT'),
				{
					name: {
						method: 'isset',
						fieldName: 'Name',
					},
					baseUrl: {
						method: 'isset',
						fieldName: 'Shop Url',
					},
					email: {
						method: 'isEmail',
						fieldName: 'E-Mail',
						errorMsg: 'Das Feld E-Mail im Tab Allgemein muss eine gültige E-Mail Adresse sein.',
					},
					companyName: {
						method: 'isset',
						fieldName: 'Unternehmen',
					},
					defaultCurrencyId: {
						method: 'isset',
						fieldName: 'Standard Währung',
						errorMsg: 'Das Feld Standard Währung auf der Registerkarte Währungen darf nicht leer sein.',
					},
					defaultLocaleId: {
						method: 'isset',
						fieldName: 'Standard Lokale',
						errorMsg: 'Das Feld Standard Lokale auf der Registerkarte Locale darf nicht leer sein.',
					},
					localeIds: {
						method: 'isset',
						fieldName: 'Lokale',
						errorMsg: 'Wählen Sie mindestens ein Lokale in der Registerkarte Lokale.',
						customValidate: function () {
							return (
								$scope.localeScope.selectedLocales !== undefined &&
								$scope.localeScope.selectedLocales.length > 0
							);
						},
					},
					street: {
						method: 'isset',
						fieldName: 'Straße',
					},
					zipCode: {
						method: 'isset',
						fieldName: 'Postleitzahl',
					},
					city: {
						method: 'isset',
						fieldName: 'Stadt',
					},
					taxId: {
						method: 'isset',
						fieldName: 'Steuersatz',
						errorMsg: 'Der Steuersatz für die Versandart darf nicht leer sein.',
					},
				}
			);

			// If shop is new, new default customer group fields are shown and we need to validate them
			if (!$scope.entity.id) {
				rules['defaultCustomerGroup.name'] = {
					method: 'isset',
					fieldName: 'Standard Kundengruppe Name',
					errorMsg: 'Das Feld "Standard Kundengruppe Name" kann nicht leer sein',
					customValidate: function (entity) {
						return entity.defaultCustomerGroup && entity.defaultCustomerGroup.name;
					},
				};
				rules['defaultCustomerGroup.groupKey'] = {
					method: 'isset',
					fieldName: 'Standard Kundengruppe Kürzel',
					errorMsg: 'Das Feld "Standard Kundengruppe Kürzel" kann nicht leer sein',
					customValidate: function (entity) {
						return entity.defaultCustomerGroup && entity.defaultCustomerGroup.groupKey;
					},
				};
			}

			var validationResult = ValidationService.validate($scope.entity, rules);

			delete $scope.entity.taxId;

			return validationResult;
		};

		$scope.data = {
			tmpweightCountries: [],
			tmpvolumeCountries: [],
			paymentMethods: [],
			selectedShippingMethodId: null,
		};

		$scope.labelSizeValues = [
			{ display: 'DIN A6', value: 'A6' },
			{ display: 'DIN A4', value: 'A4' },
		];
		$scope.yesNoRadioValues = [
			{ display: 'SETTINGS.SHOP.YES', value: true },
			{ display: 'SETTINGS.SHOP.NO', value: false },
		];
		$scope.commissioningValues = [
			{
				display: 'Shopinhaber',
				value: 'SHOP_OWNER',
			},
			{ display: 'Letzter Lieferant', value: 'LAST_ORGANISATION' },
		];
		$scope.shippingTypes = [
			{ display: 'Neutral', value: 'NEUTRAL' },
			{ display: 'Branded', value: 'BRANDED' },
		];
		$scope.deliveryTime = [
			{ display: 'Classic', value: 'Classic' },
			{
				display: 'Express_830',
				value: 'Express_830',
			},
			{ display: 'Express_10', value: 'Express_10' },
			{
				display: 'Express_12',
				value: 'Express_12',
			},
			{ display: 'Express_18', value: 'Express_18' },
		];
		$scope.dhlExpress = [
			{ display: 'None', value: 'None' },
			{
				display: 'Express_900',
				value: 'Express_900',
			},
			{ display: 'Express_1000', value: 'Express_1000' },
		];

		$scope.userActivationRadioValues = [
			{ value: true, display: 'Manual' },
			{
				value: false,
				display: 'Automatisch',
			},
		];

		$scope.datas = {};
		$scope.productService = ProductService;

		$scope.productService.setCurrentPage(0).then(function () {
			$scope.datas.dropShippingProducts = $scope.productService.getAll();
		});

		if (UserService.isSuperuser()) {
			$scope.formTabs = $scope.formTabs.concat($scope.adminFormTabs);
		}

		$scope.selected = {
			countriesCurrentPage: 0,
			countriesPageSize: 5,
			countriesTotalItems: 0,
			currenciesCurrentPage: 0,
			currenciesPageSize: 5,
			currenciesTotalItems: 0,
			localesCurrentPage: 0,
			localesPageSize: 5,
			localesTotalItems: 0,
			dropShippingsTotalItems: 10,
			dropShippingsPageSize: 10,
			dropShippingsCurrentPage: 0,
		};

		CountryService.setCurrentPage($scope.selected.countriesCurrentPage, false);
		CountryService.setPageSize($scope.selected.countriesPageSize, false);
		CurrencyService.setCurrentPage($scope.selected.currenciesCurrentPage, false);
		CurrencyService.setPageSize($scope.selected.currenciesPageSize, false);
		LocaleService.setCurrentPage($scope.selected.localesCurrentPage, false);
		LocaleService.setPageSize($scope.selected.localesPageSize, false);

		$scope.initExistingShop = function () {
			if (UserService.isSuperuser()) {
				dropShipmentTab($scope, ($scope.dropShipmentScope = {}), injectionContext);
				shippingLabelTab($scope, ($scope.shippingLabelScope = {}), injectionContext);
			}

			$scope.loadCustomerGroups();
			!!$scope.shippingLabelScope && $scope.shippingLabelScope.init();
		};

		$scope.loadCustomerGroups = function () {
			if ($scope.entity && $scope.entity.id) {
				CustomerGroupService.findByShopId($scope.entity.id).then(function (response) {
					if (response.data) {
						$scope.customerGroups = response.data;
					}
				});
			} else {
				$scope.customerGroups = [];
			}
		};

		$scope.init('Shop').then(function () {
			LocaleService.findAll().then(function () {
				ShopService.findShopLocales().then(() => {
					if ($scope.newEntity) {
						$scope.entity.isDefault = false;
						$scope.entity.isGross = false;
						$scope.entity.manualUserActivation = false;
						$scope.entity.smallestPriceInCatalog = false;
						$scope.entity.showPricesOnlyWhenLoggedIn = false;
					}

					$scope.seoSettingsScope.init();

					if ($scope.entity.shopConfiguration === undefined) {
						$scope.entity.shopConfiguration = {};
					}

					$scope.entity = ShopService.shippingMethods($scope.entity);
					$scope.shippingMethodScope.getUpdatedShippingMethods();

					if (!$scope.newEntity) {
						$scope.initExistingShop();
					}

					$scope.shippingMethodScope.init();
					$scope.currenciesScope.init();
					!!$scope.dropShipmentScope && $scope.dropShipmentScope.init();
					$scope.localeScope.init();
					ShopService.findAll(true).then((response) => {
						if (response.data) {
							$scope.shops = response.data;
						}

						$scope.copyProductsScope.init();
					});
					$scope.pluginSettingsScope.init();
					$scope.imagesScope.init();
					$scope.legalTextsScope.init();
					$scope.generalScope.init();
					$scope.searchSettingsScope.init();
					$scope.slugSettingsScope.init();

					if ($scope.entity.isGross === undefined) {
						$scope.entity.isGross = true;
					}
				});
			});
		});

		$scope.productService.setCurrentPage(0).then(function () {
			$scope.products = $scope.productService.getAll();
		});

		$scope.listState = 'root.shops';

		$scope.$on('beforeUpdate', function () {
			$scope.pluginSettingsScope.packPluginSettings();

			$scope.save();
		});

		$scope.$on('beforeCreate', function () {
			$scope.pluginSettingsScope.packPluginSettings();

			$scope.save();
		});

		$scope.$on('afterCreate', function () {
			$scope.initExistingShop();
			$scope.loadCustomerGroups();
			$scope.copyProductsScope.init();
			$scope.saveSettings();

			$rootScope.$emit('shopsChanged');
		});

		$scope.onRecipientListUpdate = () => {
			if (
				OrderManagementService.settings.eConnectConfigurationRecipientListId !=
				$scope.data.eConnectConfigurationRecipientListId
			) {
				$scope.saveSettings();
			}
		};

		$scope.saveSettings = () => {
			if ($scope.entity.id) {
				OrderManagementService.settings.eConnectConfigurationRecipientListId =
					$scope.data.eConnectConfigurationRecipientListId;
				OrderManagementService.saveSettings($scope.entity.id);
			}
		};

		$scope.save = function () {
			if (!$scope.newEntity) {
				$scope.saveSettings();
				if (!$scope.dropShipmentScope.saveDropShipping()) {
					return;
				}
			}

			$scope.slugSettingsScope.setSelectedSlugs();
			$scope.searchSettingsScope.setSelectedSearchSettings();
			$scope.shippingMethodScope.setSelectedCountries();
			$scope.currenciesScope.setSelectedCurrencies();
			$scope.localeScope.setSelectedLocales();
			$scope.generalScope.setSelectedPhones();

			if ($scope.entity.email) {
				delete $scope.entity.email;
			}

			$scope.shippingMethodScope.beforeSave();
		};

		$scope.getRealCurrentPage = function (currentPage) {
			var cur = angular.copy(parseInt(currentPage));
			cur = cur === 0 ? cur : cur - 1;
			return cur;
		};

		$scope.$on(CountryService.getType() + 'Changes', function (event, data) {
			$scope.shippingMethodScope.setTableDataCountries();
		});

		$scope.$on(CurrencyService.getType() + 'Changes', function (event, data) {
			$scope.currenciesScope.setTableDataCurrencies();
		});

		$scope.$on(DropShippingService.getType() + 'Changes', function (event, data) {
			$scope.dropShipmentScope.dropShippings = DropShippingService.getAll();
		});

		$scope.$on(LocaleService.getType() + 'Changes', function (event, data) {
			$scope.localeScope.setTableDataLocales();
		});

		$scope.defaultValueChanged = function (allValuesArray, selectedValues, selectedId) {
			allValuesArray.forEach(function (item) {
				item.disabled = false;
				if (item.id == selectedId) {
					var containsItem = false;
					selectedValues.forEach(function (selectedItem) {
						if (selectedItem.id == item.id) {
							containsItem = true;
							item.disabled = true;
							item.selected = true;
						}
					});
					if (!containsItem) {
						selectedValues.push(item);
					}
				}
			});
		};

		$scope.searchTerm;
		$scope.clearSearchTerm = function () {
			$scope.searchTerm = '';
		};

		$scope.onSearchChange = function ($event) {
			$event.stopPropagation();
		};

		$scope.tinymceOptions = {
			theme: 'modern',
			height: '500',
			convert_urls: false,
			relative_urls: false,
			remove_script_host: false,
			plugins: [
				'link advlist lists image charmap print preview hr anchor pagebreak',
				'searchreplace wordcount visualblocks visualchars code fullscreen',
				'insertdatetime media nonbreaking save table contextmenu directionality',
				'emoticons template paste textcolor colorpicker textpattern imagetools',
				'fullpage moodiaimage shortcodes',
			],
			menubar: 'insert | tools',
			toolbar1:
				'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
			toolbar2:
				'print preview media | forecolor backcolor emoticons | fontsizeselect | fontselect | code | shortcodes',
			image_advtab: true,
		};
	};
}
;
