require.config({
	map: {
		'*': { jQuery: 'jquery' },
	},

	paths: {
		// Core libraries.
		jquery: '../node_modules/jquery/dist/jquery',
		jqueryUi: '../node_modules/jquery-ui-dist/jquery-ui.min',
		angular: '../node_modules/angular/angular.min',
		ngMockE2E: '../node_modules/angular-mocks/angular-mocks',
		cookies: '../node_modules/angular-cookies/angular-cookies.min',
		uiRouter: '../node_modules/angular-ui-router/release/angular-ui-router.min',
		ngResource: '../node_modules/angular-resource/angular-resource.min',
		uiBootstrap: '../node_modules/angular-bootstrap/ui-bootstrap-tpls.min',
		'angular-translate': '../node_modules/angular-translate/dist/angular-translate',
		'angular-translate-loader-static-files':
			'../node_modules/angular-translate/dist/angular-translate-loader-static-files/angular-translate-loader-static-files.min',
		'angular-ui-tree': '../node_modules/angular-ui-tree/dist/angular-ui-tree.min',
		'angular-ui-tree-nodes': '../node_modules/angular-ui-tree/dist/angular-ui-tree.min',
		'angular-ui-tree-node': '../node_modules/angular-ui-tree/dist/angular-ui-tree.min',
		'angular-ui-tinymce': '../node_modules/angular-ui-tinymce/src/tinymce',
		'angular-ui-sortable': '../node_modules/angular-ui-sortable/dist/sortable.min',
		'angular-ui-select': '../node_modules/angular-ui-select/select.min',
		lightbox: '../node_modules/lightbox2/dist/js/lightbox.min',
		'angular-file-upload': '../node_modules/angular-file-upload/dist/angular-file-upload.min',
		'angular-bootstrap-colorpicker':
			'../node_modules/angular-bootstrap-colorpicker/js/bootstrap-colorpicker-module.min',
		'angular-drag-drop': '../drag-drop/angular-drag-drop-fixed',
		'angular-jwt': '../node_modules/angular-jwt/dist/angular-jwt.min',
		'ang-drag-drop': '../node_modules/angular-native-dragdrop/draganddrop',
		i18n: '../node_modules/angular-i18n/angular-locale_de-de',
		tinymce: '../node_modules/tinymce/tinymce.min',
		domReady: '../node_modules/domready/ready.min',
		'angular-nvd3': '../node_modules/angular-nvd3/src/angular-nvd3',
		d3: '../node_modules/d3/d3',
		nvd3: '../node_modules/nvd3/build/nv.d3.min',
		'text-sanitize': 'directives/contentConfigurator/textAngular-sanitize',
		'angular-material': '../node_modules/angular-material/angular-material.min',
		'angular-aria': '../node_modules/angular-aria/angular-aria.min',
		'angular-animate': '../node_modules/angular-animate/angular-animate.min',
		'angular-local-storage': '../node_modules/angular-local-storage/dist/angular-local-storage.min',
		ngRoute: '../node_modules/angular-route/angular-route.min',
		underscore: '../node_modules/underscore/underscore-min',
		bootstrap: '../node_modules/bootstrap/dist/js/bootstrap.min',
		'angular-css': '../node_modules/angular-css/angular-css.min',
		chart: '../node_modules/angular-chart.js/node_modules/chart.js/dist/Chart.min',
		'angular-chart': '../node_modules/angular-chart.js/dist/angular-chart.min',
		'tags-input': '../node_modules/ng-tags-input/build/ng-tags-input.min',
		spin: '../node_modules/spin/dist/spin.min',
		'angular-spinner': '../node_modules/angular-loading-spinner/node_modules/angular-spinner/angular-spinner.min',
		'angular-loading-spinner': '../node_modules/angular-loading-spinner/angular-loading-spinner',
		'angular-loading-bar': '../node_modules/angular-loading-bar/build/loading-bar.min',
		bee_plugin_blop: '../bee_plugin/Blob',
		bee_plugin_filesaver: '../bee_plugin/fileSaver',
		bee_plugin: '../bee_plugin/BeePlugin',
		'jquery-flexslider': '../node_modules/flexslider/jquery.flexslider',
		'content-editable': '../node_modules/ng-content-editable/dist/ng-content-editable.min',
		datatables: '../node_modules/datatables/media/js/jquery.dataTables.min',
		lightgallery: '../node_modules/lightgallery/dist/js/lightgallery.min',
		'lg-video': '../node_modules/lightgallery/modules/lg-video.min',
	},
	shim: {
		domReady: {
			deps: ['jquery'],
		},
		angular: {
			deps: ['jquery'],
			exports: 'angular',
		},
		ngRoute: {
			deps: ['angular'],
			exports: 'ngRoute',
		},
		ngMockE2E: {
			deps: ['angular'],
		},
		'angular-file-upload': {
			deps: ['angular'],
		},
		tinymce: {
			deps: ['jquery'],
		},
		d3: {
			deps: ['angular'],
		},
		nvd3: {
			deps: ['jquery', 'd3'],
		},
		'angular-nvd3': {
			deps: ['nvd3'],
		},
		'angular-aria': {
			deps: ['angular'],
		},
		'angular-translate': {
			deps: ['angular'],
		},
		'angular-translate-loader-static-files': {
			deps: ['angular', 'angular-translate'],
		},
		'angular-animate': {
			deps: ['angular-aria'],
		},
		'angular-ui-tinymce': {
			deps: ['angular', 'tinymce'],
		},
		'angular-ui-tree': {
			deps: ['angular'],
		},
		'angular-ui-tree-node': {
			deps: ['angular'],
		},
		'angular-ui-tree-nodes': {
			deps: ['angular'],
		},
		'angular-ui-select': {
			deps: ['angular'],
		},
		'angular-bootstrap-colorpicker': {
			deps: ['angular'],
		},
		'text-sanitize': {
			deps: ['angular'],
		},
		'angular-local-storage': {
			deps: ['angular'],
		},
		'ang-drag-drop': {
			deps: ['angular'],
		},
		'angular-material': {
			deps: ['angular-animate'],
		},
		i18n: {
			deps: ['angular'],
		},
		uiRouter: {
			deps: ['angular'],
		},
		ngResource: {
			deps: ['angular'],
		},
		cookies: {
			deps: ['angular'],
		},
		uiBootstrap: {
			deps: ['angular', 'jquery'],
		},
		jquery: {
			exports: 'jQuery',
		},
		jqueryUi: {
			deps: ['jquery'],
		},
		'angular-ui-sortable': {
			deps: ['angular', 'jqueryUi'],
		},
		underscore: {
			exports: '_',
		},
		bootstrap: {
			deps: ['jquery'],
		},
		'angular-css': {
			deps: ['angular', 'uiRouter'],
		},
		'angular-chart': {
			deps: ['chart', 'angular'],
		},
		'tags-input': {
			deps: ['angular', 'jquery'],
		},
		'angular-loading-spinner': {
			deps: ['angular', 'angular-spinner', 'spin'],
		},
		'angular-loading-bar': {
			deps: ['angular', 'angular-spinner', 'spin'],
		},
		'content-editable': {
			deps: ['angular'],
		},
		'angular-jwt': {
			deps: ['angular'],
		},
		datatables: {
			deps: ['jquery'],
		},
		lightgallery: {
			deps: ['jquery'],
		},
		'lg-video': {
			deps: ['lightgallery'],
		},
	},
	waitSeconds: 25,
	/*urlArgs: 'bust=' + new Date().getTime(),*/
});

define('main',['require', 'angular', 'app', 'i18n', 'settings', 'config'], function (require, ng) {
	'use strict';

	require(['domReady'], function (document) {
		ng.bootstrap(document, ['app']);
	});
});

