define('directives/sideModalForm/sideModalForm',['app'], function (app) {
	'use strict';
	app.directive('ngSideModalForm', [
		'$parse',
		'BASE_DIRECTIVES_PATH',
		function ($parse, BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					expanded: '=',
					title: '@',
					description: '@',
					display: '@',
					identifier: '@?',
					result: '=',
					inputs: '=',
					onSubmit: '&',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/sideModalForm/views/sideModalForm.html',
				controller: [
					'$scope',
					function ($scope) {
						$scope.closeModal = () => {
							$scope.expanded = false;
						};
					},
				],
			};
		},
	]);
});

