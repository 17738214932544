define('filters/DateFilter',['app'], function (app) {
	'use strict';
	app.filter('dateFilter', [
		function () {
			return function (isoDate, locale = { id: 1, isDefault: false, language: 'LOCALES.DE', locale: 'de' }) {
				var a = new Date(isoDate);

				var months = {
					de: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
					en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
					fr: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Okt', 'Nov', 'Déc'],
				};
				var year = a.getFullYear();
				// debugger;
				var month = months[locale.locale][a.getMonth()];
				var date = a.getDate();

				var time = date + ' ' + month + ' ' + year;
				if (isNaN(date)) {
					return '';
				}
				return time;
			};
		},
	]);
});

