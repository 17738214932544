define('directives/listSelectSku/ListSelectSku',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngListSelectSku', ['BASE_DIRECTIVES_PATH', ngListSelectSku]);
});
function ngListSelectSku(BASE_DIRECTIVES_PATH) {
	return {
		scope: {
			entityService: '=',
			itemIds: '=',
			selectedItems: '=',
			items: '=',
			fields: '=',
			name: '=',
			isCrossSelling: '=',
			isUpSelling: '=',
			isAddOnSelling: '=',
			addOnSellingProducts: '=?',
			entityId: '=',
		},
		templateUrl: BASE_DIRECTIVES_PATH + '/listSelectSku/views/view.html',
		controller: ['$scope', '$rootScope', NgListSelectSkuController],
	};
}
function NgListSelectSkuController($scope, $rootScope) {
	if (!$scope.selectedItems) {
		$scope.selectedItems = [];
	}

	$scope.query = {};
	$scope.query.filters = {};
	$scope.firstPageLoaded = false;
	$scope.data = {
		items: $scope.items,
		selectedItems: $scope.selectedItems,
	};

	$scope.$watch('data.items', function () {
		if ($scope.firstPageLoaded || !$scope.entityService) {
			return;
		}
		init();
	});

	$scope.searchInputChanged = function () {
		if ($scope.filterTimer) {
			clearTimeout($scope.filterTimer);
		}

		var textfield = this;
		$scope.filterTimer = setTimeout(function () {
			$scope.query.filters[textfield.field.attribute] = textfield.fieldModel;
			$scope.entityService.setQuery($scope.query).then(function () {
				$scope.data.items = $scope.entityService.getAll();
				$scope.totalItems = $scope.entityService.getTotalItems();
			});
		}, 500);
	};

	$scope.listSelectPageChanged = function () {
		$scope.entityService.setCurrentPage($scope.selectedPage - 1).then(function () {
			$scope.data.items = $scope.entityService.getAll();
		});
	};

	$scope.selectItems = function () {
		if (!$scope.data.items) {
			console.error("The attribute selected-items='X' is not set. Please set it to use the list control.");
			return;
		}

		if (!$scope.data.items) {
			console.error('No items');
			return;
		}

		for (var i = 0; i < $scope.data.items.length; i++) {
			var item = $scope.data.items[i];
			for (var j = 0; j < $scope.data.selectedItems.length; j++) {
				var selectedItem = $scope.data.selectedItems[j];
				if (item.id == selectedItem.id) {
					if (!item[$scope.name]) {
						item[$scope.name] = {};
					}
					item[$scope.name].selected = false;
					item[$scope.name].added = true;
					selectedItem.name = item.name;
				}
			}
		}
	};

	$scope.toggleOptional = (id, optional) => {
		for (let i in $scope.itemIds) {
			if ($scope.itemIds[i].productId === id) {
				$scope.itemIds[i].optional = optional;
			}
		}
	};

	$scope.addItems = function () {
		for (var i = 0; i < $scope.data.items.length; i++) {
			if ($scope.data.items[i][$scope.name] && $scope.data.items[i][$scope.name].selected) {
				$scope.data.selectedItems.push($scope.data.items[i]);
				$scope.data.items[i][$scope.name].added = true;
				$scope.data.items[i][$scope.name].selected = false;
				if ($scope.isCrossSelling) {
					$scope.itemIds.push({ productId: $scope.data.items[i].id, sellingType: 'CROSS_SELLING' });
				} else if ($scope.isUpSelling) {
					$scope.itemIds.push({ productId: $scope.data.items[i].id, sellingType: 'UP_SELLING' });
				} else if ($scope.isAddOnSelling) {
					$scope.itemIds.push({
						productId: $scope.data.items[i].id,
						sellingType: 'ADD_ON_SELLING',
						optional: $scope.data.items[i].optional ? $scope.data.items[i].optional : false,
					});
				} else {
					$scope.itemIds.push($scope.data.items[i].id);
				}
			}
		}

		$rootScope.$broadcast('addonProductAdded', $scope.itemIds);
	};

	$scope.removeItems = function () {
		for (var i = 0; i < $scope.data.selectedItems.length; i++) {
			if ($scope.data.selectedItems[i][$scope.name] && $scope.data.selectedItems[i][$scope.name].selected) {
				for (var j = 0; j < $scope.data.items.length; j++) {
					if ($scope.data.items[j].id == $scope.data.selectedItems[i].id) {
						$scope.data.items[j][$scope.name].added = false;
						$scope.data.items[j][$scope.name].selected = false;
					}
				}
				if ($scope.isCrossSelling || $scope.isUpSelling || $scope.isAddOnSelling) {
					for (var k = 0; k < $scope.itemIds.length; k++) {
						if ($scope.itemIds[k].productId == $scope.data.selectedItems[i].id) {
							$scope.itemIds.splice(k, 1);
						}
					}
				} else {
					for (var k = 0; k < $scope.itemIds.length; k++) {
						if ($scope.itemIds[k] == $scope.data.selectedItems[i].id) {
							$scope.itemIds.splice(k, 1);
						}
					}
				}
				$scope.data.selectedItems.splice(i, 1);
				i--;
			}
		}
	};

	$scope.itemsLeftSelected = function () {
		if ($scope.data.items != undefined) {
			for (var i = 0; i < $scope.data.items.length; i++) {
				if (
					$scope.data.items[i][$scope.name] &&
					$scope.data.items[i][$scope.name].selected &&
					!$scope.data.items[i][$scope.name].added
				) {
					return true;
				}
			}
			return false;
		}
		return false;
	};

	$scope.itemsRightSelected = function () {
		for (var i = 0; i < $scope.data.selectedItems.length; i++) {
			if (!$scope.data.selectedItems[i][$scope.name]) {
				return false;
			}

			if ($scope.data.selectedItems[i][$scope.name].selected) {
				return true;
			}
		}
		return false;
	};

	var init = function () {
		$scope.entityService.getAdditionalParams().push({ name: 'exclude_product_id', value: $scope.entityId });

		$scope.entityService.setCurrentPage(0).then(function () {
			$scope.data.items = $scope.entityService.getAll();
			$scope.totalItems = $scope.entityService.getTotalItems();
			$scope.selectItems();
			$scope.firstPageLoaded = true;
		});
	};

	init();
}
;
