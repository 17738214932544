define('filters/productTypeFilter',['app'], function (app) {
	'use strict';
	app.filter('productTypeFilter', function () {
		return function (item) {
			if (item === 'SIMPLE') {
				return 'PRODUCT_TYPES.SIMPLE';
			}
			if (item === 'BUNDLE') {
				return 'PRODUCT_TYPES.BUNDLE';
			}
			if (item === 'CONFIGURABLE') {
				return 'PRODUCT_TYPES.CONFIG';
			}

			return item;
		};
	});
});

