define('modules/settings/shop/tabs/CurrenciesTabController',[], function () {
	'use strict';

	return function CurrenciesController(
		$parentScope,
		$scope,
		{ BASE_TEMPLATES_PATH, CurrencyService, BankAccountService, TaxService }
	) {
		$parentScope.adminFormTabs.push({
			label: 'NAVIGATION.CURRENCIES',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-currencies.html',
			service: CurrencyService,
		});

		$scope.selectedCurrencies = [];
		$scope.newCurrencyBankAccountAssignment = {};

		$scope.setDefaultTax = (res) => {
			$parentScope.entity.defaultTax = $scope.taxes.find((x) => x.id === $scope.defaultTaxId);
		};

		$scope.init = () => {
			TaxService.findAll().then(function () {
				$scope.taxes = TaxService.getAll(true);
			});

			CurrencyService.findAll().then(function () {
				$scope.currencies = CurrencyService.getAll(true);
				$parentScope.selected.currenciesTotalItems = CurrencyService.getTotalItems();
				$parentScope.selected.currenciesPageSize = CurrencyService.getPageSize();
				$scope.markSelectedCurrencies();
			});

			BankAccountService.findAll().then(function () {
				$scope.bankAccounts = BankAccountService.getAll();
				for (var i = 0; i < $scope.bankAccounts.length; i++) {
					var bankAccount = $scope.bankAccounts[i];
					bankAccount.display = bankAccount.bankName + ' ' + bankAccount.bankIban;
				}
			});
		};

		$scope.markSelectedCurrencies = function () {
			for (var key in $scope.currencies) {
				if ($parentScope.entity.currencyIds != undefined) {
					for (var i = 0; i < $parentScope.entity.currencyIds.length; i++) {
						if ($parentScope.entity.currencyIds[i] == $scope.currencies[key].id) {
							$scope.currencies[key].selected = true;
						}
					}
				}
			}
		};

		$scope.addCurrency = function () {
			if (!$parentScope.entity.shopCurrencyConfigs) {
				$parentScope.entity.shopCurrencyConfigs = [];
			}
			$parentScope.entity.shopCurrencyConfigs.push({});
		};

		$scope.addBankAccount = function (currencyConfig) {
			if (!$parentScope.entity.shopCurrencyConfigs) {
				$parentScope.entity.shopCurrencyConfigs = [];
			}
			$parentScope.entity.shopCurrencyConfigs.push({ currencyId: currencyConfig.currencyId });
		};

		$scope.removeCurrencyConfig = function (index) {
			$parentScope.entity.shopCurrencyConfigs.splice(index, 1);
		};

		$scope.currenciesTableOptions = {};
		$scope.currenciesTableOptions.fields = [
			{
				heading: 'FIELDS.NAME',
				attribute: 'name',
			},
			{
				heading: 'FIELDS.ISO_CODE',
				attribute: 'isoCode',
			},
			{
				heading: 'FIELDS.UNICODE_SYMBOL',
				attribute: 'unicodeSymbol',
			},
		];

		$scope.setSelectedCurrencies = function () {
			$parentScope.entity.currencyIds = [];
			$scope.selectedCurrencies.forEach(function (currency) {
				$parentScope.entity.currencyIds.push(currency.id);
			});
		};

		$scope.currenciesSetPage = function () {
			CurrencyService.setCurrentPage($scope.getRealCurrentPage($parentScope.selected.currenciesCurrentPage));
		};

		$scope.setTableDataCurrencies = function () {
			$parentScope.selected.currenciesPageSize = CurrencyService.getPageSize();
			$parentScope.selected.currenciesTotalItems = CurrencyService.getTotalItems();

			$scope.currenciesTotalPages = CurrencyService.getTotalPages();

			if ($scope.currenciesTotalPages === 0) {
				//If there are no items the total pages size should be displayed as 1
				$scope.currenciesTotalPages = 1;
			}

			$parentScope.selected.currenciesCurrentPage = CurrencyService.getCurrentPage();
			$scope.currencies = CurrencyService.getAll();
			$parentScope.$broadcast('tableDataRefreshed');
		};

		$scope.defaultCurrencyChanged = function () {
			$parentScope.defaultValueChanged(
				$scope.currencies,
				$scope.selectedCurrencies,
				$parentScope.entity.defaultCurrencyId
			);
		};
	};
});

